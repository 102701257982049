export default {
    namespaced: true,
    state: {
        preferredUnits: {
            temperature: "°F",
            windspeed: "mph",
            pressure: "hPa",
            rainfall: "inches"
        }
    },
    mutations: {
        SET_PREFERRED_UNIT(state, args) {
            state.preferredUnits[args.metric] = args.unit;
        }
    },
    actions: {

    }
}
