<template>
  <div>
    <!--<a @click="forcePlay">Play</a>-->
    <audio ref="player">
    </audio>
  </div>
</template>

<script>
  import {mapState, mapMutations} from 'vuex';
  export default {
    name: 'AudioPlayer',
    computed: {
      ...mapState('data', {
        audio: state => state.audio
      }),
    },
    methods: {
      ...mapMutations('data', [
        'SET_AUDIO_MUTE'
      ]),
      play() {
        if(this.audio.source !== null && this.audio.source.streams !== undefined && 
            !this.audio.muted) {
          let foundStream = false;
          for(let stream of this.audio.source.streams) {
            if(this.$refs.player.canPlayType(stream.type)) {
              this.$refs.player.src = stream.src;
              console.log("canPlay", stream.src);
              foundStream = true;
              break;
            }
          }
          if(foundStream) {
            if(!this.audio.muted && this.$refs.player.paused) {
              this.$refs.player.play();
              setTimeout(() => {
                  if(this.$refs.player.paused) {
                  this.SET_AUDIO_MUTE(true);
                  }
                  }, 500);
            }
            this.$refs.player.muted = this.audio.muted;

          } else {
            this.$refs.player.pause();
          }
        } else {
          this.$refs.player.pause();
        }

      }
    },
    watch: {
      "audio.source"() {
        this.play();
      },
      "audio.muted"() {
        this.play();
      }
    }

  }
</script>

