import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Data from './views/Data.vue';
import MapView from './views/MapView.vue';
import TimelapseTest from './views/TimelapseTest.vue';

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/data', redirect: '/data/herring'
    },
    {
      path: '/site.html', redirect: '/data/herring'
    },
    {
      path: '/data/:site',
      component: Data
    },
    {
      path: '/map',
      component: MapView
    },
    {
      path: '/camera/:camera',
      component: TimelapseTest,
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import(/* webpackChunkName: "privacy" */ './views/Privacy.vue')
    }
  ]
})
