import io from '@/socket-instance';
import sanitize from 'sanitize-filename';
import axios from 'axios';

export default {
    namespaced: true,
    state: {
        datasets: [
            {
                key: 'naip',
                title: 'USDA NAIP',
                description: `Aerial imagery acquired by the USDA during agricultural
                    growing seasons`
            },
            {
                key: 'hro',
                title: 'USGS High Resolution Orthoimagery',
                description: `Digital images of orthorectified aerial photos`
            }
        ],
        baseLayers: [
            {
                key: 'naip2018',
                dataset: 'naip',
                year: 2018,
                type: 'Visible',
                url: '/maptiles/naip2018/{z}/{x}/{y}.png',
                title: 'NAIP 2018 Visible',
                options: {
                    maxZoom: 19
                },
                attrib: 'USDA NAIP',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-national-agriculture-imagery-program-naip?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'naip2018ir',
                dataset: 'naip',
                year: 2018,
                type: 'Near IR',
                url: '/maptiles/naip2018ir/{z}/{x}/{y}.png',
                title: 'NAIP 2018 Near IR',
                options: {
                    maxZoom: 19
                },
                attrib: 'USDA NAIP',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-national-agriculture-imagery-program-naip?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'naip2016',
                dataset: 'naip',
                year: 2016,
                type: 'Visible',
                url: '/maptiles/naip2016/{z}/{x}/{y}.png',
                title: 'NAIP 2016 Visible',
                options: {
                    maxZoom: 19
                },
                attrib: 'USDA NAIP',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-national-agriculture-imagery-program-naip?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'naip2016ir',
                dataset: 'naip',
                year: 2016,
                type: 'Near IR',
                url: '/maptiles/naip2016ir/{z}/{x}/{y}.png',
                title: 'NAIP 2016 Near IR',
                options: {
                    maxZoom: 19
                },
                attrib: 'USDA NAIP',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-national-agriculture-imagery-program-naip?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'naip2014',
                dataset: 'naip',
                year: 2014,
                type: 'Visible',
                url: '/maptiles/naip2014/{z}/{x}/{y}.png',
                title: 'NAIP 2014 Visible',
                options: {
                    maxZoom: 19
                },
                attrib: 'USDA NAIP',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-national-agriculture-imagery-program-naip?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'naip2014ir',
                dataset: 'naip',
                year: 2014,
                type: 'Near IR',
                url: '/maptiles/naip2014ir/{z}/{x}/{y}.png',
                title: 'NAIP 2014 Near IR',
                options: {
                    maxZoom: 19
                },
                attrib: 'USDA NAIP',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-national-agriculture-imagery-program-naip?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'naip2012',
                dataset: 'naip',
                year: 2012,
                type: 'Visible',
                url: '/maptiles/naip2012/{z}/{x}/{y}.png',
                title: 'NAIP 2012 Visible',
                options: {
                    maxZoom: 19
                },
                attrib: 'USDA NAIP',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-national-agriculture-imagery-program-naip?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'naip2012ir',
                dataset: 'naip',
                year: 2012,
                type: 'Near IR',
                url: '/maptiles/naip2012ir/{z}/{x}/{y}.png',
                title: 'NAIP 2012 Near IR',
                options: {
                    maxZoom: 19
                },
                attrib: 'USDA NAIP',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-national-agriculture-imagery-program-naip?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'naip2010',
                dataset: 'naip',
                year: 2010,
                type: 'Visible',
                url: '/maptiles/naip2010/{z}/{x}/{y}.png',
                title: 'NAIP 2010 Visible',
                options: {
                    maxZoom: 19
                },
                attrib: 'USDA NAIP',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-national-agriculture-imagery-program-naip?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'naip2010ir',
                dataset: 'naip',
                year: 2010,
                type: 'Near IR',
                url: '/maptiles/naip2010ir/{z}/{x}/{y}.png',
                title: 'NAIP 2010 Near IR',
                options: {
                    maxZoom: 19
                },
                attrib: 'USDA NAIP',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-national-agriculture-imagery-program-naip?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'naip2008',
                dataset: 'naip',
                year: 2008,
                type: 'Visible',
                url: '/maptiles/naip2008/{z}/{x}/{y}.png',
                title: 'NAIP 2008 Visible',
                options: {
                    maxZoom: 19
                },
                attrib: 'USDA NAIP',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-national-agriculture-imagery-program-naip?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'naip2003',
                dataset: 'naip',
                year: 2003,
                type: 'Visible',
                url: '/maptiles/naip2003/{z}/{x}/{y}.png',
                title: 'NAIP 2003 Visible',
                options: {
                    maxZoom: 19
                },
                attrib: 'USDA NAIP',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-national-agriculture-imagery-program-naip?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'hro2013',
                dataset: 'hro',
                year: 2013,
                type: 'Visible',
                url: '/maptiles/hro2013/{z}/{x}/{y}.png',
                title: 'USGS High Resolution Orthoimagery 2013 Visible',
                options: {
                    maxZoom: 19
                },
                attrib: 'USGS High Resolution Orthoimagery',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-high-resolution-orthoimagery-hro?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'hro2013ir',
                dataset: 'hro',
                year: 2013,
                type: 'Near IR',
                url: '/maptiles/hro2013ir/{z}/{x}/{y}.png',
                title: 'USGS High Resolution Orthoimagery 2013 Near IR',
                options: {
                    maxZoom: 19
                },
                attrib: 'USGS High Resolution Orthoimagery',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-high-resolution-orthoimagery-hro?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'hro2008',
                dataset: 'hro',
                year: 2008,
                type: 'Visible',
                url: '/maptiles/hro2008/{z}/{x}/{y}.png',
                title: 'USGS High Resolution Orthoimagery 2008 Visible',
                options: {
                    maxZoom: 19
                },
                attrib: 'USGS High Resolution Orthoimagery',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-high-resolution-orthoimagery-hro?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'hro2005',
                dataset: 'hro',
                year: 2005,
                type: 'Visible',
                url: '/maptiles/hro2005/{z}/{x}/{y}.png',
                title: 'USGS High Resolution Orthoimagery 2005 Visible',
                options: {
                    maxZoom: 19
                },
                attrib: 'USGS High Resolution Orthoimagery',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-high-resolution-orthoimagery-hro?qt-science_center_objects=0#qt-science_center_objects'
            },
            {
                key: 'hro2005ir',
                dataset: 'hro',
                year: 2005,
                type: 'Near IR',
                url: '/maptiles/hro2005ir/{z}/{x}/{y}.png',
                title: 'USGS High Resolution Orthoimagery 2005 Near IR',
                options: {
                    maxZoom: 19
                },
                attrib: 'USGS High Resolution Orthoimagery',
                attribUrl: 'https://www.usgs.gov/centers/eros/science/usgs-eros-archive-aerial-photography-high-resolution-orthoimagery-hro?qt-science_center_objects=0#qt-science_center_objects'
            },
        ],
        overlayLayers: [
            {
                key: 'interfluve',
                type: 'Visible',
                url: '/maptiles/interfluve/{z}/{x}/{y}.png',
                title: 'Inter-Fluve Drone Imagery',
                options: {
                    maxZoom: 22
                },
                attrib: 'Inter-Fluve',
                attribUrl: 'http://interfluve.com'
            },
            {
                key: 'usgs2019ir',
                type: 'Thermal',
                url: '/maptiles/usgs2019ir/{z}/{x}/{y}.png',
                title: '2019 Stream Thermal Imagery',
                options: {
                    maxZoom: 19
                },
                attrib: 'USGS',
                attribUrl: 'https://doi.org/10.5066/P9X8PBWN'
            },
            {
                key: 'usgs2019visible',
                type: 'Visible',
                url: '/maptiles/usgs2018visible/{z}/{x}/{y}.png',
                title: 'March 2018 Stream Channel Visible Imagery',
                options: {
                    maxZoom: 22
                },
                attrib: 'USGS',
                attribUrl: 'https://doi.org/10.5066/P9X8PBWN'
            },
            {
                key: 'gpr',
                url: '/geojson/gpr.geojson',
                title: 'GPR Depth of Peat',
                attrib: 'Danielle Hare',
            },
        ],
        activeBaseLayer: 'naip2018',
        activeOverlayLayers: [],
        center: [41.905260, -70.56798],
        zoom: 16,
        maxZoom: 22,
        
        activeRealtimeLayers: ['sensors'],
        activeSensorMetric: "temperature",
        sensors: {},
        sensorMetrics: [
            {id: "temperature", title: "Temperature"},
            {id: "humidity", title: "Humidity"},
        ],

        geoJsonLayers: [],
        activeGeoJsonLayers: [],
    },
    mutations: {
        SET_BASE_LAYER(state, layer) {
            state.activeBaseLayer = layer;
        },
        SELECT_OVERLAY_LAYERS(state, layers) {
            state.activeOverlayLayers = layers;
        },
        START_LOADING(state) {
            state.sensors = {};
        },
        SOCKET_MAP_UPDATE(state, data) {
            if(state.sensors === undefined) return;
            for(let key of Object.keys(data.sensors)) {
                let updatedDevice = data.sensors[key];
                let device = state.sensors[key];

                if(device === undefined) {
                    state.sensors[key] = updatedDevice;
                    continue;
                }

                device.last_seen = updatedDevice.last_seen;
                if(device.metrics === undefined) {
                    device.metrics = {};
                }

                if(updatedDevice.metrics === undefined) {
                    continue;
                }

                for(let metricName of Object.keys(updatedDevice.metrics)) {
                    let updatedMetric = updatedDevice.metrics[metricName];
                    if(device.metrics[metricName] === undefined) {
                        device.metrics[metricName] = updatedMetric;
                    } else {
                        device.metrics[metricName].value = updatedMetric.value;
                        device.metrics[metricName].timestamp = updatedMetric.timestamp;
                    }
                }
            }
        },
        SET_SENSORS(state, sensors) {
            state.sensors = sensors;
        },
        SELECT_REALTIME_LAYERS(state, layers) {
            state.activeRealtimeLayers = layers;
        },
        SELECT_SENSOR_METRIC(state, metric) {
            state.activeSensorMetric = metric;
        },
        START_GEOJSON_LOADING(state) {
            state.geoJsonLayers = [];
        },
        SET_GEOJSON_LAYER(state, {key, data}) {
            if(state.geoJsonLayers === undefined || state.geoJsonLayers === null) {
                state.geoJsonLayers = [];
            }

            for(let i=0; i<state.geoJsonLayers.length; i++) {
                if(state.geoJsonLayers[i].key === key) {
                    state.geoJsonLayers[i] = data;
                    return;
                }
            }
            state.geoJsonLayers.push(data);
        },
        SELECT_GEOJSON_LAYERS(state, layers) {
            state.activeGeoJsonLayers = layers.slice();
        }
    },
    actions: {
        loadMap({commit}) {
            console.log('loadMap');
            commit('START_LOADING');
            io.emit('loadMap', (data) => {
                commit('SET_SENSORS', data.sensors);
            });
        },
        leaveMap({commit}) {
            console.log('leaveMap');
            io.emit('leaveMap');
        },
        async loadGeoJsonLayers({commit}, layers) {
            commit('START_GEOJSON_LOADING');
            for(let layer of layers) {
                let filename = sanitize(layer);
                let url = `/geojson/${filename}.geojson`;
                try {
                    let result = await axios.get(url);
                    let data = {
                        data: result.data,
                        key: filename,
                    };
                    commit('SET_GEOJSON_LAYER', {key: filename, data});
                } catch(e) {
                    console.log(`error fetching GeoJSON layer ${layer}: ${e.toString()}`);
                }
            }
            commit('SELECT_GEOJSON_LAYERS', layers);
        },
    }
}
