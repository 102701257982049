<template>
  <data-section class="map-container">
    <div class="map">
      <l-map :center="[41.905260, -70.56798]" :zoom="16" :maxZoom="22">
        <l-control-layers ref="layercontrol"/>
        <l-control-scale position="bottomleft"/>
        <l-tile-layer url="/maptiles/naip/{z}/{x}/{y}.png"
          attribution='USDA NAIP 2016' :tms="true" :options="{maxZoom: 19}"
          name="USDA NAIP 2016" layer-type="base"/>
        <l-tile-layer url="/maptiles/interfluve/{z}/{x}/{y}.png"
          attribution='Inter-Fluve' :tms="true" :options="{maxZoom: 22}"
          name="Inter-fluve Drone Imagery" layer-type="overlay" :visible="true"/>
        <l-tile-layer url="/maptiles/usgs2018visible/{z}/{x}/{y}.png"
          attribution='<a href="https://doi.org/10.5066/P9X8PBWN">USGS</a>' :tms="true" :options="{maxZoom: 22}"
          name="USGS 2018 Visible" layer-type="overlay" :visible="false" ref="usgsvis"/>
        <l-tile-layer url="/maptiles/usgs2019ir/{z}/{x}/{y}.png"
          attribution='<a href="https://doi.org/10.5066/P9X8PBWN">USGS</a>' :tms="true" :options="{maxZoom: 19}"
          name="USGS 2019 Thermal infrared" layer-type="overlay" :visible="false"/>
        <l-feature-group name="Sensor Nodes" layer-type="overlay">
          <l-marker v-for="(device, key) in devices" :key="key"
            :latLng="[device.props['loc:lat'], device.props['loc:long']]">
            <l-popup>
              <map-sensor-popup :device="device"/>
            </l-popup>
          </l-marker>
        </l-feature-group>
      </l-map>
    </div>
  </data-section>
</template>

<script>
  import {mapState} from 'vuex';

  import DataSection from '@/components/DataSection.vue';
  import MapSensorPopup from '@/components/MapSensorPopup.vue';
  import {LMap, LTileLayer, LMarker, LPopup, LControlLayers,
    LFeatureGroup, LControlScale} from 'vue2-leaflet';
  import {Icon} from 'leaflet';

  delete Icon.Default.prototype._getIconUrl;

  Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  });

  export default {
    name: 'SiteMap',
    props: {
      devices: Object
    },
    components: {
      DataSection,
      MapSensorPopup,
      LMap, LTileLayer, LMarker, LPopup, LControlLayers, LFeatureGroup,
      LControlScale
    },
    mounted() {
      this.$nextTick(() => {
      });
    }
  }
</script>

<style>
  .map-container {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
  }

  .map {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
</style>
