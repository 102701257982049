<template>
  <div id="main-view">
    <top-nav/>
    <slot/>
    <b-container id="s-logos" class="p-0">
      <b-row>
        <b-col cols="auto" class="mr-auto">
          <a href="http://www.media.mit.edu/"><img src="../../public/img/logo_ml.svg" alt="mit media lab"/></a>
          <a href="http://resenv.media.mit.edu/"><img src="../../public/img/logo_resenv.svg" alt="responsive environments"/></a>
        </b-col>
        <b-col cols="auto" id="footer-links">
          <router-link to="/privacy">Privacy policy</router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import TopNav from '@/components/TopNav.vue';

  export default {
    name: 'MainView',
    components: {
      TopNav
    },
    computed: {
    }
  }
</script>

<style>
body {
    background: #222;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: white;
}

a {
    color: #c33;
    outline: none;
}

#main-view {
    max-width: 1100px;
    margin: 0 auto 0 auto;
    background: #444;
    position: relative;
    left: 0;
    top: 0;
}


#s-logos {
    background: #222;
}

#s-logos img {
    height: 64pt;
    margin: 0;
    padding: 0;
    border: 0;
}

#footer-links a {
  color: white;
  text-decoration: underline;
  font-size: 10pt;
}

@media (max-width: 600px) {
  body {
    font-size: 10pt;
  }
}

</style>
