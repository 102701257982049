const colormaps = {
    temperature: [
        {val: -10, color: [0, 255, 255]},
        {val: 0, color: [0, 0, 255]},
        {val: 30, color: [255, 0, 0]},
        {val: 40, color: [255, 200, 0]},
    ],

    humidity: [
        {val: 0, color: [0, 0, 255]},
        {val: 50, color: [0, 200, 0]},
        {val: 100, color: [100, 255, 255]},
    ],

    soil_moisture: [
        {val: 500, color: [155, 135, 108]},
        {val: 700, color: [0, 0, 255]},
        {val: 900, color: [0, 255, 255]},
    ],

    uvi: [
        {val: 0, color: [0, 200, 0]},
        {val: 4, color: [255, 255, 0]},
        {val: 7, color: [255, 150, 0]},
        {val: 9, color: [255, 0, 0]},
        {val: 11, color: [200, 0, 220]},
    ]
};

const scales = {
    temperature: {
        title: 'Temperature',
        colormap: colormaps.temperature,
        unit: "°C",
        precision: 0,
        ticks: [-10, 0, 20, 40],
        type: 'linear'
    },

    humidity: {
        title: 'Humidity',
        colormap: colormaps.humidity,
        unit: "%",
        precision: 0,
        ticks: 5,
        type: 'linear'
    },

    soil_moisture: {
        title: 'Soil Moisture',
        colormap: colormaps.soil_moisture,
        unit: "mV",
        precision: 0,
        ticks: 5,
        type: 'linear'
    },

    soil_temperature: {
        title: 'Soil Temperature',
        colormap: colormaps.temperature,
        unit: "°C",
        precision: 0,
        ticks: [-10, 0, 20, 40],
        type: 'linear'
    },

    uvi: {
        title: "UV Index",
        colormap: colormaps.uvi,
        unit: "",
        precision: 0,
        ticks: 5,
        type: 'linear',
    }
};

const metricScales = {
    temperature: scales.temperature,
    humidity: scales.humidity,
    soil_moisture_5cm: scales.soil_moisture,
    soil_moisture_15cm: scales.soil_moisture,
    soil_temperature_5cm: scales.soil_temperature,
    soil_temperature_15cm: scales.soil_temperature,
    light_uvi: scales.uvi,
    light_uva: scales.uvi,
    light_uvb: scales.uvi,
};

function interpColor(scale, value, unit) {
    if(value < scale.colormap[0].val) {
        return scale.colormap[0].color;
    }
    for(let i=0; i<scale.colormap.length-1; i++) {
        let s1 = scale.colormap[i];
        let s2 = scale.colormap[i+1];
        if(value >= s1.val && value < s2.val) {
            let range = s2.val - s1.val;
            let offset = (value - s1.val) / range;
            return [
                s1.color[0] * (1.0 - offset) + s2.color[0] * offset,
                s1.color[1] * (1.0 - offset) + s2.color[1] * offset,
                s1.color[2] * (1.0 - offset) + s2.color[2] * offset,
            ]
        }
    }
    return scale.colormap[scale.colormap.length-1].color;
}

module.exports = {
    scales: metricScales,
    interpColor
}
