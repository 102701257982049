<template>
  <div class="audio-overlay" @click="clearMenu">
    <div class="dropdown-content" :class="{open: dropdown }">
      <a v-for="(source, index) in sources" :key="source.id"
        @click.stop="setIndex(index)">{{source.title}}</a>
    </div>
    <div class="audio-controls" 
     :class="{hidden: hidden && !dropdown, expanded: expanded || dropdown}" 
     @mouseover="expanded=true"
     @mouseleave="expanded=false">
      <div id="audio-flex">
        <div id="mute-toggle" @click.stop="toggleMute">
          <font-awesome-icon :icon="muteUnmuteIcon"/>
        </div>
        <div id="stream-selector" @click.stop="dropdown=!dropdown" :class="{active: dropdown}">
          <div class="source-title">{{activeSource.title}}</div>
          <font-awesome-icon icon="chevron-down"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    name: 'AudioOverlay',
    props: {
      sources: Array,
      visible: Boolean
    },
    data() {
      return {
        expanded: false,
        dropdown: false,
        selectedIndex: 0
      }
    },
    computed: {
      ...mapState('data', {
        audio: state => state.audio
      }),
      muteUnmuteIcon() {
        return this.audio.muted? 'volume-mute': 'volume-up';
      },
      hidden() {
        return !this.visible && !this.hold;
      },
      activeSource() {
        if(this.audio.source !== null) {
          return this.audio.source;
        } else {
          return {
            id: 'null-source',
            title: 'No Audio'
          }
        }
      }
    },
    methods: {
      setIndex(i) {
        this.selectedIndex = i;
        this.clearMenu();
        this.$store.commit('data/SET_AUDIO_SOURCE', this.sources[this.selectedIndex]);
        this.$store.commit('data/SET_AUDIO_MUTE', false);
        this.$emit('click');
      },
      clearMenu() {
        this.dropdown = false;
      },
      toggleMute() {
        this.$store.commit('data/SET_AUDIO_MUTE', !this.audio.muted);
        this.$emit('click');
      },
    }
  }
</script>

<style scoped>
  .audio-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 16px;
    z-index: 1000;
  }

  .audio-controls {
    background: rgba(0,0,0,0.8);
    border-radius: 8px;
    transition: opacity 0.5s, max-width 0.5s;
    max-width: 48px;
    height: 48px;
    display: inline-block;
    overflow: hidden;
  }

  #mute-toggle {
    padding: 8px;
    font-size: 18pt;
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }

  #audio-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
  }

  #stream-selector {
    margin-right: 8px;
    padding: 8px;
    white-space: nowrap;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    position: relative;
    cursor: pointer;
  }

  #stream-selector:hover {
    background: #444;
    border-radius: 4px;
  }

  #stream-selector.active {
    background: #722;
    border-radius: 4px;
  }

  .source-title {
    margin-right: 9px;
  }

  .dropdown-content {
    display: inline-block;
    visibility: hidden;
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
    z-index: 2000;
    left: 64px;
    top: 58px;
    opacity: 0;
    transition: opacity 200ms, visibility 200ms;
  }

  .dropdown-content.open {
    visibility: visible;
    opacity: 1;
  }

  .dropdown-content a {
    font-weight: bold;
    display: block;
    padding: 8px;
    color: white;
    text-decoration: none;
    cursor: pointer;
    background: black;
  }

  .dropdown-content a+a {
    border-top: 1px solid #444;
  }

  .dropdown-content a:hover {
    background: #333;
  }

  .dropdown-content a:active {
    background: #722;
  }

  .audio-controls.hidden {
    opacity: 0;
  }

  .audio-controls.expanded {
    max-width: 300px;
  }
</style>
