<template>
  <div class="tabbar">
    <ul>
      <li v-for="(item, index) of items" :key="item.id">
        <a href="#" :class="{active: index == value}"
          @click.prevent="$emit('input', index)">{{item.title}}</a>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'TabBar',
    props: {
      items: Array,
      value: {
        type: Number,
        default: 0
      }
    }
  }
</script>

<style scoped>
  .tabbar {
    border-bottom: 2px solid #777;
    display: flex;
    justify-content: space-between;
  }

  ul {
    margin: 0px;
    padding: 0px;
  }

  ul li {
    display: inline-block;
    padding: 0px;
    margin: 0px;
    height: 32px;
  }

  a {
    display: block;
    padding: 8px;
    padding-left: 18px;
    padding-right: 18px;
    text-decoration: none;
    height: 32px;
    color: #fff;
  }

  a:hover {
    background: #666;
  }

  a.active {
    background: #777;
    font-weight: bold;
  }

</style>
