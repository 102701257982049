<template>
  <div ref="widget" class="timelapse-widget">
    <div class="timelapse-widget-inner">
    <b-container v-if="error !== undefined" class="mt-3">
      <b-alert v-if="error !== undefined" variant="danger" show>{{error}}</b-alert>
    </b-container>
    <loading-spinner v-else-if="masterIndex === undefined"/>
    <div v-else>
      <div class="camera-container">
        <div class="camera-inner" v-if="index === undefined">
          <loading-spinner class="camera-spinner" />
        </div>
        <timelapse-player v-else ref="player" :url="playlist" :pos="curPos" @poschange="posChange">
          <div class="time-overlay" v-if="showOverlay">
            <div>{{localeDate}}</div>
            <div v-if="showTime">{{localeTime}}</div>
          </div>
          <div class="seek-overlay" v-if="$refs.player === undefined || $refs.player.seeking">
            <div class="seek-inner">
              <b-spinner style="width: 3rem; height: 3rem;"/>
            </div>
          </div>
        </timelapse-player>
      </div>

      <div class="controls">
        <div class="control-row">
          <div class="button-row">
            <b-button @click="$refs.player.playpause()" class="mr-3" variant="primary">
              <fa-icon icon="play" v-if="$refs.player === undefined || $refs.player.paused"/>
              <fa-icon icon="pause" v-else/>
            </b-button>
            <b-form-radio-group buttons :options="speeds"
              v-model="cursor.speed" class="mr-3"/>
            <div>
              <b-button @click="decMonth" class="mr-1"><fa-icon icon="fast-backward"/></b-button>
              <b-button @click="decDay" class="mr-1"><fa-icon icon="step-backward"/></b-button>
              <b-button @click="incDay" class="mr-1"><fa-icon icon="step-forward"/></b-button>
              <b-button @click="incMonth" class="mr-1"><fa-icon icon="fast-forward"/></b-button>
            </div>
          </div>
          <div>
            <b-form inline>
              <input type="date" :min="isoDate(masterIndex.start)"
                :max="isoDate(masterIndex.end)" v-model="curDate" class="mr-3"/> 
              <input type="time" v-model="curTime" v-if="showTime" class="mr-3"/>
              <b-form-checkbox v-model="showOverlay">Show Overlay</b-form-checkbox>
            </b-form>
          </div>
          <div>
            <b-button class="mr-1" id="btn-settings">
              <fa-icon icon="cog"/>
            </b-button>
            <b-popover target="btn-settings" triggers="click" placement="topleft"
              variant="dark">
              <b-form-group label="Quality" v-if="$refs.player !== undefined">
                <b-form-select v-model="level">
                  <option :value="-1">{{autoLevelText}}</option>
                  <option v-for="level, index in $refs.player.levels" :key="`level-${index}`"
                    :value="index">
                    {{level.height}}p
                  </option>
                </b-form-select>
              </b-form-group>
            </b-popover>
            <b-button @click="toggleFullscreen">
              <fa-icon icon="compress"/>
            </b-button>
          </div>
        </div>
        <!--
        <div class="control-row">
          <b-form-radio-group buttons :options="years"
            :checked="cursor.year"/>
        </div>
        -->
      </div>
    </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import Hls from 'hls.js/dist/hls.light.min.js';
  import moment from 'moment';
  
  import {mapState} from 'vuex';
  
  import LoadingSpinner from '@/components/LoadingSpinner';
  import TimelapsePlayer from '@/components/TimelapsePlayer';

  export default {
    name: 'TimelapseViewer',
    props: {
      url: String
    },
    components: {
      LoadingSpinner,
      TimelapsePlayer
    },
    methods: {
      load() {
        this.loadCamera(this.$route.params.camera);
      },
      async loadCamera(cam) {
        this.cam = cam;
        console.log('loadCamera', cam);
        this.masterIndex = undefined;
        this.index = undefined;
        this.error = undefined;
        let result;
        try {
          result = await axios.get(`/timelapse/${cam}/index.json`, {
            responseType: 'json',
          });
        } catch(e) {
          this.error = `Failed to load camera: ${e.toString()}`;
          return;
        }
        this.masterIndex = result.data;
        this.masterIndex.start = moment(this.masterIndex.start * 1000);
        this.masterIndex.end = moment(this.masterIndex.end * 1000);
        this.cursor.speed = this.speeds[0];
        this.curTimestamp = this.masterIndex.start.clone().hour(12);
        if(this.$route.query.s !== undefined) {
          if(this.speeds.indexOf(this.$route.query.s) !== -1) {
            this.cursor.speed = this.$route.query.s;
          }
        }
        let d = this.$route.query.d;
        if(d !== undefined && d !== null) {
          let targetDate = moment(d, "YYYY-MM-DD");
          if(targetDate.isValid()) {
            this.curDate = targetDate;
          }
        }

        let t = this.$route.query.t;
        if(t !== undefined && t !== null) {
          let targetTime = moment(t, "HH.mm");
          if(targetTime.isValid()) {
            this.curTime = targetTime.format('HH:mm');
          }
        }
      },
      async loadIndex() {
        this.index = undefined;
        if(this.cursor.year !== undefined && this.cursor.speed !== undefined) {
          let indexUri = `/timelapse/${this.cam}/${this.cursor.speed}/${this.cursor.year}/index.json`;
          let playlistUri = `/timelapse/${this.cam}/${this.cursor.speed}/${this.cursor.year}/index.m3u8`;
          let result;
          try {
            result = await axios.get(indexUri, {
              responseType: 'json',
            });
          } catch(e) {
            this.error = `Failed to load index: ${e.toString()}`;
            return;
          }
          this.index = result.data;
          this.playlist = playlistUri;
        }  
      },
      isoDate(ts) {
        return moment(ts).format('YYYY-MM-DD');
      },
      posChange(pos) {
        this.curPos = pos;
      },
      updateQuery() {
        window.history.replaceState({}, "", this.$route.path + `?s=${this.cursor.speed}` +
          `&d=${this.curDate}&t=${this.curTimestamp.format('HH.mm')}`);
      },
      incDay() {
        let targetTimestamp = this.curTimestamp.clone();
        if(this.curTimestamp.hour() >= 9) {
          targetTimestamp.add({days: 1});
        }
        targetTimestamp.hour(9);
        targetTimestamp.minute(0);
        this.curTimestamp = targetTimestamp;
      },
      decDay() {
        let targetTimestamp = this.curTimestamp.clone();
        targetTimestamp.add({days: -1});
        targetTimestamp.hour(9);
        targetTimestamp.minute(0);
        this.curTimestamp = targetTimestamp;
      },
      incMonth() {
        let targetTimestamp = this.curTimestamp.clone();
        targetTimestamp.add({months: 1});
        targetTimestamp.hour(9);
        targetTimestamp.minute(0);
        this.curTimestamp = targetTimestamp;
      },
      decMonth() {
        let targetTimestamp = this.curTimestamp.clone();
        targetTimestamp.add({months: -1});
        targetTimestamp.hour(9);
        targetTimestamp.minute(0);
        this.curTimestamp = targetTimestamp;
      },
      toggleFullscreen() {
        if(document.fullscreenElement !== null) {
          document.exitFullscreen();
        } else {
          this.$refs.widget.requestFullscreen();
        }
      },
    },
    computed: {
      years() {
        if(this.masterIndex === undefined) return [];
        let start = moment(this.masterIndex.start);
        let end = moment(this.masterIndex.end);
        let years = [];
        for(let year = start.year(); year <= end.year(); year++) {
          years.push(year);
        }
        return years;
      },
      speeds() {
        if(this.masterIndex === undefined) return [];
        return this.masterIndex.speeds;
      },
      levels() {
        if(this.$refs.player === undefined) return [];
        return this.$refs.player.levels;
      },
      level: {
        get() { return this.$refs.player.level; },
        set(value) { this.$refs.player.level = value; }
      },
      autoLevelText() {
        if(this.$refs.player === undefined || this.$refs.player.level != -1) {
          return "Auto";
        }
        let level = this.levels[this.$refs.player.currentLevel];
        return level === undefined? "Auto (...)" : `Auto (${level.height}p)`;
      },
      curTimestamp: {
        get() {
          return this.wallclock;
        },
        set(val) {
          let ts = moment(val);
          if(this.masterIndex === undefined || ts > this.masterIndex.end ||
            ts < this.masterIndex.start) return;
          this.wallclock = ts;
          if(this.index === undefined || this.cursor.year != ts.year()) {
            this.cursor.year = ts.year();
          }
        }
      },
      showTime() {
        return this.cursor.speed === "300x" || this.cursor.speed === "1800x";
      },
      localeDate() {
        return moment(this.curDate).format("L");
      },
      localeTime() {
        return moment(this.curTimestamp).format("LT");
      },
      curDate: {
        get() {
          return this.isoDate(this.wallclock);
        },
        set(val) {
          let input = moment(val);
          let newWallclock = this.wallclock.clone();
          newWallclock.year(input.year());
          newWallclock.month(input.month());
          newWallclock.date(input.date());
          this.curTimestamp = newWallclock;
        }
      },
      curTime: {
        get() {
          return this.wallclock.format('HH:mm');
        },
        set(val) {
          this.wallclock = moment(`${this.curDate} ${val}`);
        }
      },
      curPos: {
        get() {
          if(this.index === undefined) return 0;
          let ts = this.curTimestamp.valueOf() / 1000;
          let rate = this.index.rate;
          let lo=0, hi=this.index.segments.length-1, mid;
          while(lo <= hi) {
            mid = Math.floor((lo+hi)/2);
            let seg = this.index.segments[mid];
            if(ts > seg.start && ts < seg.start + seg.duration*rate) {
              return seg.ts + (ts - seg.start)/rate;
            } else if(ts > seg.start) {
              lo = mid+1;
            } else {
              hi = mid-1;
            }
          }
          if(mid === 0) return 0;
          return this.index.duration;
        },
        set(pos) {
          if(this.index === undefined) return 0;
          let rate = this.index.rate;
          let lo=0, hi=this.index.segments.length-1, mid;
          while(lo <= hi) {
            mid = Math.floor((lo+hi)/2);
            let seg = this.index.segments[mid];
            if(pos > seg.ts && pos < seg.ts + seg.duration) {
              this.wallclock = moment((seg.start + (pos - seg.ts)*rate) * 1000);
              return;
            } else if(pos > seg.ts) {
              lo = mid+1;
            } else {
              hi = mid-1;
            }
          }
          if(mid === 0) this.wallclock = moment(this.index.start * 1000);
          else this.wallclock = moment(this.index.end * 1000);
        }
      },
    },
    mounted() {
      this.load();
    },
    destroyed() {
    },
    data() {
      return {
        error: undefined,
        masterIndex: undefined,
        index: undefined,
        cam: undefined,
        playlist: undefined,
        wallclock: undefined,
        showOverlay: true,
        cursor: {
          speed: undefined,
          year: undefined,
        }
      }
    }, 
    watch: {
      '$route'(to, from) {
        this.load();
      },
      'cursor.year'() {
        this.loadIndex();
      },
      'cursor.speed'() {
        this.loadIndex();
        this.updateQuery();
      },
      curTimestamp() {
        this.updateQuery();
      }
    }
  }
</script>

<style scoped>
  .control-row {
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .controls {
    background: #444;
  }

  .button-row {
    display: flex;
    flex-direction: row;
  }

  .camera-inner {
  }

  video {
    width: 100%;
  }

  .time-overlay {
    position: absolute;
    right: 0;
    top: 0;
    margin: 12px;
    background: rgb(0, 0, 0, 0.4);
    padding: 8px;
    font-size: 14pt;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
  }

  .time-overlay div {
    margin-left: 8px;
    margin-right: 8px;
  }

  .seek-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .seek-inner {
    width: 128px;
    height: 128px;
    background: rgb(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

  }

  .timelapse-widget {
    display: flex;
    align-items: center;
  }

  .timelapse-widget-inner {
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    max-height: 100%;
  }

  .timelapse-widget:fullscreen .controls {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 2000;
  }


</style>
