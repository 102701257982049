<template>
  <div>
    <camera-viewer v-if="cameras !== undefined" :cameras="cameras"/>
    <plot-viewer v-if="plots !== undefined" :plots="plots"/>
    <site-map :devices="devices"/>
  </div>
</template>

<script>
  import {mapState} from 'vuex';

  import CameraViewer from '@/components/CameraViewer.vue';
  import PlotViewer from '@/components/PlotViewer.vue';
  import SiteMap from '@/components/SiteMap.vue';

  export default {
    name: 'DataDisplay',
    components: {
      CameraViewer,
      PlotViewer,
      SiteMap
    },
    computed: {
      ...mapState('data', {
        cameras: state => state.site.cameras,
        devices: state => state.site.devices,
        plots: state=> state.site.plots
      })
    }
  };
</script>

<style>
</style>
