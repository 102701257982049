<template>
  <div class="sensor-popup">
    <h3>{{device.alias}}</h3>
    <img class="photo" :v-if="photo !== null" :src="photo"/>
    <ul>
      <li><b>Deployed</b> {{formatDate(device.props['dep:date'])}} 
        <b>by</b> {{device.props['dep:deployed_by']}}</li>
      <li><b>Last seen online</b> {{formatRelTime(device.last_seen)}}</li>
    </ul>
    <ul class="metric-list">
      <li v-for="metric in displayMetrics" :key="metric.data.name"><b>{{metric.title}}:</b>
        {{metric.data.value}} {{metric.data.unit}}</li>
    </ul>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'MapSensorPopup',
    props: {
      device: Object
    },
    methods: {
      formatDate: d => moment(d).format('D MMM YYYY'),
      formatRelTime: d => moment(d).fromNow()
    },
    computed: {
      displayMetrics() {
        let dMetrics = [];
        if(this.metricList === undefined) return [];
        for(let metric of this.metricList) {
          if(this.device.metrics.hasOwnProperty(metric.key)) {
            dMetrics.push({ title: metric.title, data: this.device.metrics[metric.key]})
          }
        }
        return dMetrics;
      },
      photo() {
        if(this.device.photos === undefined) return null;
        for(let photo of this.device.photos) {
          if(photo.tags.includes('node')) {
            return '/' + photo.display;
          }
        }
        if(this.device.photos.length > 0) {
          return '/' + this.device.photos[0].display;
        }
        return null;
      }
    },
    data() {
      return {
        metricList: [
          {key: 'temperature', title: 'Temperature'},
          {key: 'humidity', title: 'Humidity'},
          {key: 'pressure', title: 'Barometer'},
          {key: 'battery_voltage', title: 'Battery'},
          {key: 'light_als', title: 'Light'},
          {key: 'soil_moisture_5cm', title: 'Soil Moisture (5cm)'},
          {key: 'soil_moisture_15cm', title: 'Soil Moisture (15cm)'},
          {key: 'soil_temperature_5cm', title: 'Soil Temperature (5cm)'},
          {key: 'soil_temperature_15cm', title: 'Soil Temperature (15cm)'}
        
        ]
      }
    }
  }
</script>

<style>
  .sensor-popup ul {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
  }

  .sensor-popup ul li {
    margin-left: 0;
    padding-left: 0;
  }

  .metric-list {
  }

  .leaflet-popup-scrolled {
    border: none !important;
  }

  .photo {
    max-height: 200px;
  }
</style>
