<template>
  <div class="calendar-bar">
    <div class="calendar-bar-inner">
      <div>
        Show:
        <radio-button :items="timeOptions" :value="selection"
          @input="setSelection"/>
      </div>
      <template v-if="isCustom">
        <div class="cal-input-group">From: <input type="date" :value="fromDate" @input="setFrom"/></div>
        <div class="cal-input-group">Until: <input type="date" :value="untilDate" @input="setUntil"/></div>
      </template>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import RadioButton from '@/components/RadioButton.vue';
  import moment from 'moment';
 
  export default {
    name: 'CalendarBar',
    components: {
      RadioButton
    },
    computed: {
      ...mapState('data', {
        plotSpan: state => state.plotSpan
      }),
      selection() {
        if(this.plotSpan.from.startsWith("-")) {
          return this.plotSpan.from;
        } else {
          return "dates";
        }
      },
      isCustom() {
        return !this.plotSpan.from.startsWith("-");
      },
      fromDate() {
        let from = this.plotSpan.from;
        let rel_days = from.match(/^\-([0-9]*)days/);
        if(rel_days !== null) {
          return moment().subtract(parseInt(rel_days[1]), 'day').format('YYYY-MM-DD');
        } else {
          return moment(from, "HH:mm_YYYYMMDD").format('YYYY-MM-DD');
        }
      },
      untilDate() {
        let until = this.plotSpan.until;
        if(until == 'now') {
          return moment().format('YYYY-MM-DD');
        } else {
          return moment(until, "HH:mm_YYYYMMDD").format('YYYY-MM-DD');
        }
      },
    },
    methods: {
      setSelection(value) {
        if(value.startsWith('-')) {
          this.$store.commit('data/SET_PLOT_SPAN', {
            from: value,
            until: 'now'
          });
        } else {
          this.setFrom(this.fromDate);
        }
      },
      setFrom(e) {
        if(e.target !== undefined) {
          e = e.target.value;
        }
        this.$store.commit('data/SET_PLOT_SPAN', {
          from: '00:00_' + e.replace(/\-/g, ''),
          until: this.plotSpan.until
        });
      },
      setUntil(e) {
        this.$store.commit('data/SET_PLOT_SPAN', {
          from: this.plotSpan.from,
          until: '00:00_' + e.target.value.replace(/\-/g, '')
        });
      }
    },
    data() {
      let now = new Date().toISOString().split('T')[0];
      return {
        timeOptions: [
          {id: '-1days', title: 'Today'},
          {id: '-7days', title: 'This Week'},
          {id: '-30days', title: 'This Month'},
          {id: 'dates', title: 'Custom'}
        ],
        dateSpan: {
          from: now,
          until: now
        }
      }
    },
    watch: {
      "dateSpan.from"() {
        this.setCustomDate();
      },
      "dateSpan.to"() {
        this.setCustomDate();
      },
    }
  }
</script>

<style scoped>
  .calendar-bar {
    background: #555;
  }

  .calendar-bar-inner {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 8px;
  }

  .cal-input-group {
    display: inline-block;
    padding: 2px;
  }

  input[type=date] {
    background: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    color: white;
  }
</style>
