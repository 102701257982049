<template>
  <ul class="camera-strip">
    <CameraThumbnail v-for="(camera, index) in cameras" 
      :camera="camera" :key="camera.id" :active="index == value"
      @click="$emit('input', index)"/>
  </ul>
</template>

<script>
  import CameraThumbnail from '@/components/CameraThumbnail.vue';

  export default {
    name: 'CameraStrip',
    components: {
      CameraThumbnail
    },
    props: {
      cameras: Array,
      value: Number
    }
  }
</script>

<style>
  .camera-strip {
    position: relative;
    margin: 0;
    padding: 0;
    padding-top: 6px;
  }
</style>
