<template>
  <div class="radio-button">
    <template v-for="item in items">
      <div v-if="typeof(item) == 'string'" class="radio-item" :key="item"
        :class="{selected: item===value}"
        @click="$emit('input', item)">{{item}}</div>
      <div v-else class="radio-item" :key="item.id"
        :class="{selected: item.id===value}"
        @click="$emit('input', item.id)">{{item.title}}</div>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'RadioButton',
    props: {
      items: Array,
      value: String
    }
  }
</script>

<style scoped>
  .radio-button {
    display: inline-block;
    padding: 4px;
  }

  .radio-item:first-child {
    border-radius: 4px 0 0 4px;
  }

  .radio-item:last-child {
    border-radius: 0 4px 4px 0;
  }

  .radio-item {
    display: inline-block;
    border: 1px solid #ccc;
    padding: 2px 8px 2px 8px;
    cursor: pointer;
  }

  .radio-item+.radio-item {
    border-left: none;
  }

  .radio-item.selected {
    background: #ccc;
    color: #222;
  }
</style>
