<template>
  <l-marker  v-if="validLoc && metric !== undefined" :lat-lng="latLng" @mouseover="showPopup=true" @mouseleave="showPopup=false">
    <l-popup>
      <map-sensor-popup :device="sensor"/>
    </l-popup>
    <l-icon :icon-size="[width,height]" :icon-anchor="[width/2,height/2]" :popup-anchor="[0, -height/2]">
      <div class="sensor-detail-popup" v-if="showPopup">{{value}}</div>
      <svg class="sensor-marker" :width="width" :height="height" >
        <circle :cx="width/2" :cy="height/2" :r="width/2-2" 
          :fill="color" />
      </svg>
    </l-icon>
  </l-marker>
</template>

<script>
  import {mapState} from 'vuex';
  import {LMarker, LIcon, LPopup} from 'vue2-leaflet';
  import MapSensorPopup from '@/components/MapSensorPopup';
  import {scales, interpColor} from '@/scales';
  export default {
    name: 'SensorMarker',
    props: {
      sensor: Object
    },
    components: {
      LMarker, LIcon, LPopup,
      MapSensorPopup
    },
    computed: {
      ...mapState('map', [
        'activeSensorMetric'
      ]),
      validLoc() {
        return this.sensor.props !== undefined && this.sensor.props['loc:lat'] !== undefined
          && this.sensor.props['loc:long'] !== undefined;
      },
      latLng() {
        return [this.sensor.props['loc:lat'], this.sensor.props['loc:long']];
      },
      metric() {
        if(this.activeSensorMetric === 'light_uvi') {
          let uva = this.sensor.metrics.light_uva;
          let uvb = this.sensor.metrics.light_uvb;
          if(uva === undefined || uvb === undefined) {
            return undefined;
          }
          return {
            name: 'light_uvi',
            unit: 'UVI',
            value: (uva.value + uvb.value) / 2.0,
            timestamp: uva.timestamp
          }
        } 
        return this.sensor.metrics[this.activeSensorMetric];
      },
      value() {
        if(this.metric === undefined) return undefined;
        return this.metric.value;
      },
      scale() {
        return scales[this.activeSensorMetric];
      },
      color() {
        if(this.metric === undefined || this.scale === undefined) return 'black';
        let color = interpColor(this.scale, this.metric.value, this.metric.unit);
        return `rgb(${color[0]},${color[1]},${color[2]})`;
      }
    },
    methods: { 
      onClick() {
        console.log('click');
      }
    },
    data() {
      return {
        width: 24,
        height: 24,
        showPopup: false
      }
    }
  }
</script>

<style scoped>
  .sensor-detail-popup {
    position: absolute;
    border-radius: 8px;
    background: #333;
    padding: 2px 6px 2px 6px;
    font-weight: bold;
    bottom: 30px;
  }

  .sensor-marker circle {
    stroke: black;
  }

  .sensor-marker:hover circle {
    stroke: white;
    stroke-width: 2;
  }
</style>
