<template>
  <div>
    <div id="nav">
      <h1>Tidmarsh Living Observatory</h1>
      <div id="hamburger" @click="collapsed = !collapsed">
        <font-awesome-icon icon="bars"/>
      </div>
        <div class="nav-collapse" :class="{collapsed}">
          <ul class="nav-toplevel">
            <router-link tag="li" v-for="link in links" 
              :key="link.id" 
              :to="link.to || `/${link.id}`" 
              :exact="link.exact">
              <a>{{link.title}}</a>
              <div class="nav-submenu" v-if="link.subitems !== undefined">
                <font-awesome-icon class="nav-submarker" icon="chevron-right"/>
                <ul>
                  <router-link tag="li" v-for="sublink in link.subitems"
                    :key="`${link.id}-${sublink.id}`" 
                    :to="sublink.to || `/${link.id}/${sublink.id}`">
                    <a>{{sublink.title}}</a>
                  </router-link>
                </ul>
              </div>
            </router-link>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    name: 'TopNav',
    data() {
      return {
        collapsed: true,
      }
    },
    methods: {
      toggleCollapse() {
        if(collapsed) {
          
        }
        collapsed = !collapsed;
      }
    },
    computed: {
      ...mapState('data', {
        sites: state => state.sites
      }),
      links() {
        return [
          {title: 'Home', id:'home', to: '/', exact: true},
          {title: 'Data', id: 'data', subitems: this.sites},
          {title: 'Map', id: 'map'}
        ];
      }
    }
  }
</script>

<style>
#nav {
    background: #722;
    border-bottom: 2px solid #933;
    padding: 0;
    margin: 0;
    background-image: url('../../public/img/logo_livingobs.svg');
    background-repeat: no-repeat;
    background-size: auto 46px;
    position: relative;
}

#nav a {
  color: white;
  text-decoration: none;
}

#nav h1 {
    display: inline-block;
    margin: 0;
    padding: 12px;
    font-size: 14pt;
    font-weight: bold;
    padding-left: 48px;
    margin-top: 2px;
    height: 48px;
}

.nav-collapse {
  display: inline-block;
  transition: max-height 1s ease;
  max-height: 300px;
  margin: 0;
}

#nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-toplevel li {
    margin: 0;
    display: inline-block;
    padding: 14px;
    font-weight: bold;
    height: 100%;
    padding-left: 18px;
    padding-right: 18px;
    text-decoration: none;
    color: #fff;
}

.nav-toplevel li:not(.router-link-active) .nav-submenu {
  display: none;
}

.nav-submenu {
  display: inline-block;
  vertical-align: top;
  margin: -8px;
}

.nav-submarker {
  margin: 8px;
  margin-bottom: 0px;
  padding-left: 4px;
  font-size: 16pt;
}

.nav-submenu ul {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  margin: 0;
}

.nav-submenu li {
  padding: 8px;
  font-weight: normal;
}


.nav-toplevel li.router-link-active {
    background: #933;
    font-weight: bold;
}

.nav-submenu li.router-link-active {
  background: #444;
  border-radius: 4px;
}

#hamburger {
  display: none;
  font-size: 20pt;
  padding: 8px;
  padding-top: 0px;
  width: 39px;
  height: 39px;
  border: 1px solid #944;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  right: 8px;
}

@media (max-width: 1100px) {
  #nav h1 {
    font-size: 11pt;
  }

  .nav-collapse {
    display: block;
    width: 100%;
    overflow: hidden;
  }

  .nav-toplevel {
    display: block;
    width: 100%;
  }

  .nav-toplevel li {
    display: block;
  }

  #hamburger {
    display: block;
  }

  .nav-collapse.collapsed {
  transition: max-height 1s ease;
  max-height: 0;
  }
}

</style>
