<template>
  <data-section>
    <tab-bar :items="plots" v-model="activeTab">
      <tab-bar-widgets :items="tabWidgets" v-model="activeWidget"/>
      <!--
      <font-awesome-icon icon="calendar-alt" class="tool-icon"/>
      <font-awesome-icon icon="cog" class="tool-icon selected"/>
      -->
    </tab-bar>
    <!--<unit-bar/>-->
    <collapse-transition>
      <component class="widget-bar" :is="activeWidget"/>
    </collapse-transition>
    <div class="plots">
      <graphite-plot v-for="plot in activePlots" :key="plot.id" :config="plot"/>
    </div>
  </data-section>
</template>

<script>
  import DataSection from '@/components/DataSection.vue';
  import TabBar from '@/components/TabBar.vue';
  import TabBarWidgets from '@/components/TabBarWidgets.vue';
  import GraphitePlot from '@/components/GraphitePlot.vue';
  import UnitBar from '@/components/UnitBar.vue';
  import CalendarBar from '@/components/CalendarBar.vue';
  import CollapseTransition from '@/transitions/CollapseTransition.vue';

  export default {
    name: 'PlotViewer',
    components: {
      DataSection,
      TabBar,
      TabBarWidgets,
      GraphitePlot,
      UnitBar,
      CalendarBar,
      CollapseTransition
    },
    props: {
      plots: Array
    },
    computed: {
      activePlots() {
        return this.plots[this.activeTab].plots;
      }
    },
    data() {
      return {
        activeTab: 0,
        tabWidgets: [
          {id: 'calendar', icon: 'calendar-alt', component: CalendarBar},
          {id: 'settings', icon: 'cog', component: UnitBar}
        ],
        activeWidget: CalendarBar
      }
    }
  }
</script>

<style scoped>
  .plots {
    text-align: center;
    background: #777;
  }

  .plots img {
    margin: 8px;
    max-width: 90%;
  }

  .widget-bar {
  }


</style>
