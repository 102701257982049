<template>
  <div class="camera-inner">
    <video playsinline="true" ref="player" muted @click="playpause"></video>
    <div class="video-overlayer" @click="playpause">
      <div class="video-widgetconatiner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import Hls from 'hls.js/dist/hls.light.min.js';
  import {mapState} from 'vuex';

  export default {
    name: 'TimelapsePlayer',
    props: {
      url: String,
      pos: {
        type: Number,
        default: 0
      }
    },
    methods: {
      loadVideo(url) {
        if(Hls.isSupported()) {
          this.hls.loadSource(url);
        } else {
          this.$refs.player.src = url;
        }
      },
      playVideo() {
        this.$refs.player.play();
      },
      playpause() {
        if(this.$refs.player.paused) {
          this.$refs.player.play();
        } else {
          this.$refs.player.pause();
        }
      }
    },
    computed: {
      level: {
        get() {
          return this.autoLevelEnabled? -1 : this.currentLevel;
        }, set(value) {
          this.autoLevelEnabled = (value == -1);
          this.hls.currentLevel = value;
        }
      },
    },
    mounted() {
      this.$nextTick(function() {
        let self=this;
        if(Hls.isSupported()) {
          this.hls.attachMedia(this.$refs.player);
          this.hls.on(Hls.Events.MANIFEST_PARSED, function() {
            self.$refs.player.currentTime = self.pos;
            self.levels = self.hls.levels;
            //self.playVideo();
          });
          this.hls.on(Hls.Events.LEVEL_SWITCHED, (e, level) => {
            console.log('level', level);
            this.currentLevel = level.level;
          });
        } else {
          this.$refs.player.addEventListener('loadedmetada', function() {
            self.$refs.player.currentTime = self.pos;
            //self.playVideo();
          });
        }
        this.$refs.player.addEventListener('timeupdate', () => {
          if(!this.suppressNextUpdate) {
            this.$emit('poschange', this.$refs.player.currentTime);
          }
          this.suppressNextUpdate = false;
        });
        this.$refs.player.addEventListener('play', () => {
          this.paused = false;
        });
        this.$refs.player.addEventListener('pause', () => {
          this.paused = true;
        });
        this.$refs.player.addEventListener('seeking', () => {
          this.seeking = true;
        });
        this.$refs.player.addEventListener('seeked', () => {
          this.seeking = false;
        });
        this.loadVideo(this.$props.url);
      });
    },
    destroyed() {
      this.hls.destroy();
    },
    data() {
      return {
        ready: false,
        suppressNextUpdate: true,
        paused: true,
        seeking: false,
        autoLevelEnabled: true,
        currentLevel: -1,
        levels: [],
        hls: new Hls({
          abrEwmaDefaultEstimate: 20000000,
        }),
      }
    }, 
    watch: {
      url(to, from) {
        this.loadVideo(to);
      },
      pos(to, from) {
        this.suppressNextUpdate = true;
        if(Math.abs(this.$refs.player.currentTime - to) > 1.0 || this.$refs.player.paused) {
          this.$refs.player.currentTime = to;
          console.log('setPos', to);
        }
      }
    }
  }
</script>

<style scoped>
  .camera-inner {
  }

  video {
    width: 100%;
  }

  .video-overlayer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1000;
  }

  .video-widgetcontainer {
    position: relative;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
  }

</style>
