<template>
  <l-control position="bottomright">
    <svg class="sensor-legend" :width="width" :height="height">
      <defs>
        <linearGradient id="scaleGradient" y1="100%" y2="0%" x1="0%" x2="0%">
          <stop v-for="stop, index in stops" :key="index" :offset="stop.offset" :stop-color="stop.color"/>
        </linearGradient>
      </defs>
      <rect :x="width-barWidth-4" y="4.5" :width="barWidth+3" :height="barHeight+3" fill="white"/>
      <rect :x="width-barWidth-2.5" y="6" :width="barWidth" :height="barHeight" stroke="black" fill="url(#scaleGradient)"/>
      <g v-for="tick, index in tickLocs" :key="index">
        <rect :x="width-barWidth-8" :y="tick.y-1" width="4" height="2" stroke="white" fill="white"/>
        <line :x1="width-barWidth-7" :y1="tick.y" :x2="width-barWidth-2" :y2="tick.y" stroke="black"/>
        <text :x="width-barWidth-14" :y="tick.y" text-anchor="end" :dominant-baseline="tick.baseline"
          stroke="white" stroke-width="3">
          {{tick.val.toFixed(scale.precision)}} {{unit}}
        </text>
        <text :x="width-barWidth-14" :y="tick.y" text-anchor="end" :dominant-baseline="tick.baseline">
          {{tick.val.toFixed(scale.precision)}} {{unit}}
        </text>
      </g>
      <text text-anchor="end" :x="width-4" :y="height-6" stroke="white" stroke-width="3">{{scale.title}}</text>
      <text text-anchor="end" :x="width-4" :y="height-6" fill="black">{{scale.title}}</text>
    </svg>
  </l-control>
</template>

<script>
  import {LControl} from 'vue2-leaflet';
  export default {
    name: 'GradLegend',
    props: {
      scale: Object
    },
    components: {
      LControl
    },
    computed: {
      tickLocs() {
        let ticks = [];
        let range = this.maxVal - this.minVal;
        if(typeof(this.scale.ticks) === 'number') {
          let step = this.barHeight / (this.scale.ticks-1);
          let valStep = range / (this.scale.ticks-1);
          for(let i=0; i<this.scale.ticks; i++) {
            ticks.push({
              y: this.barHeight - i * step + 6,
              val: this.minVal + i * valStep,
              baseline: i == 0 ? 'auto' : 'central'
            });
          }
        } else {
          for(let tick of this.scale.ticks) {
            if(tick > this.maxVal || tick < this.minVal) continue;
            let offset = (tick - this.minVal) / range;
            ticks.push({
              y: this.barHeight - offset * this.barHeight + 6,
              val: tick,
              baseline: tick == this.scale.ticks[0] ? 'auto' : 'central'
            });
          }
        }
        return ticks;
      },
      stops() {
        let stops = [];
        let range = this.maxVal - this.minVal;
        for(let stop of this.scale.colormap) {
          let offset = (stop.val - this.minVal) / range * 100;
          stops.push({
            offset: `${offset}%`,
            color: `rgb(${stop.color[0]},${stop.color[1]},${stop.color[2]})`
          });
        }
        return stops;
      },
      unit() {
        return this.scale.unit;
      },
      minVal() {
        return this.scale.colormap[0].val;
      },
      maxVal() {
        return this.scale.colormap[this.scale.colormap.length-1].val;
      }
    },
    data() {
      return {
        width: 100,
        height: 200,
        barWidth: 12,
        barHeight: 168,
        precision: 0,
      }
    }
  }
</script>

<style scoped>
  .sensor-legend {
    font-size: 9pt;
    font-weight: bold;
  }
</style>
