<template>
  <div class="page">
    <div class="banner" v-if="banner !== undefined"><img :src="banner" alt=""/></div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PageTemplate',
    props: {
      banner: String
    }
  }
</script>

<style>
  .banner {
    width: 100%;
    position: relative;
  }

  .banner img {
    max-width: 100%;
    height: auto;
  }

  .content {
      padding: 16px;
      overflow: hidden;
  }

</style>
