<template>
  <ul class="sensor-layer-selector">
    <li v-for="layer, index in layers" :key="layer.metric"
      @click.prevent="selectTopLayer(layer)">
      <div class="layer-icon-container" :class="{active: isActive(layer)}">
        <img :src="getIcon(layer)" />
      </div>
      <div class="layer-title" :class="{active: isActive(layer)}">
        {{layer.title}}
      </div>
      <collapse-transition>
        <ul v-if="layer.subitems !== undefined && isActive(layer)">
          <li v-for="subitem in layer.subitems" :key="subitem.metric"
            @click.prevent="$emit('input', subitem.metric)">
            <div class="sublayer-icon">
              <font-awesome-icon icon="arrow-right" v-if="value == subitem.metric"/>
            </div>
            <div class="layer-title">{{subitem.title}}</div>
          </li>
        </ul>
      </collapse-transition>
    </li>
  </ul>
</template>

<script>
  import CollapseTransition from '@/transitions/CollapseTransition';
  export default {
    name: 'SensorLayerSelector',
    props: {
      value: {
        type: String,
        default: "temperature"
      }
    },
    components: {
      CollapseTransition
    },
    methods: {
      getIcon(layer) {
        return require(`@/assets/icons/${layer.icon}.svg`);
      },
      isActive(layer) {
        if(layer.metric !== undefined && layer.metric === this.value) return true;
        if(layer.subitems !== undefined) {
          for(let subitem of layer.subitems) {
            if(subitem.metric === this.value) return true;
          }
        }
        return false;
      },
      selectTopLayer(layer) {
        if(this.isActive(layer)) return;
        if(layer.subitems !== undefined) {
          this.$emit('input', layer.subitems[0].metric);
        } else {
          this.$emit('input', layer.metric);
        }
      }
    },
    data() {
      return {
        layers: [
          {metric: "temperature", title: "Temperature", icon: "temperature"},
          {metric: "humidity", title: "Humidity", icon: "humidity"},
          {title: "Soil Moisture", icon: "soil_moisture", subitems: [
            {metric: "soil_moisture_5cm", title: "5 cm"},
            {metric: "soil_moisture_15cm", title: "15 cm"},
          ]},
          {title: "Soil Temperature", icon: "soil_temperature", subitems: [
            {metric: "soil_temperature_5cm", title: "5 cm"},
            {metric: "soil_temperature_15cm", title: "15 cm"},
          ]},
          {title: "UV Index", icon: "ultraviolet", subitems: [
            {metric: "light_uvi", title: "Composite"},
            {metric: "light_uva", title: "UV A"},
            {metric: "light_uvb", title: "UV B"},
          ]},
        ],
      }
    }
  }
</script>

<style scoped>
  .sensor-layer-selector {
    list-style-type: none;
    padding-left: 16px;
    font-size: 9pt;
  }

  .sensor-layer-selector li {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .sensor-layer-selector li > ul {
    flex-basis: 100%;
  }

  .sensor-layer-selector li > ul > li {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .sublayer-icon {
    width: 16px;
  }

  .layer-icon-container img {
    width: 32px;
    height: 32px;
    padding: 6px;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .layer-icon-container {
    position: relative;
    width: 32px;
    height: 32px;
    margin-right: 6px;
  }

  .layer-icon-container.active {
    background: #933;
    border-radius: 16px;
    box-shadow: 1px 1px 2px #000;
  }

  .layer-title.active {
    text-decoration: underline;
  }
</style>
