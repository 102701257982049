<template>
  <div id="map-sidebar" :class="{collapsed}">
    <div id="sidebar-content" :class="{collapsed}">
      <sidebar-section title="Base Map">
        <b-form-group label="Dataset">
          <b-form-select v-model="activeDataset" @input="selectDataset">
            <option v-for="dataset in datasets" :key="dataset.key"
              :value="dataset.key">
              {{dataset.title}}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group label="Image Type">
          <b-form-select v-model="activeDatasetType" @input="selectLayerType">
            <option v-for="type in datasetTypes" :key="type" :value="type">
              {{type}}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group label="Year">
          <b-form-select v-model="activeDatasetYear" @input="selectYear">
            <option v-for="year in datasetYears" :key="year.toString()" :value="year">
              {{year}}
            </option>
          </b-form-select>
        </b-form-group>
      </sidebar-section>
      
      <sidebar-section title="Overlay Maps">
        <b-form-checkbox-group :checked="activeOverlayLayers" @change="SELECT_OVERLAY_LAYERS">
          <b-form-checkbox v-for="layer in overlayLayers" :key="layer.key"
            :value="layer.key">
            {{layer.title}}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </sidebar-section>

      <sidebar-section title="Sensor Network" :visible="true">
        <b-form-checkbox-group :checked="activeRealtimeLayers" @change="SELECT_REALTIME_LAYERS">
          <b-form-checkbox value="sensors">Sensors</b-form-checkbox><br/>
          <collapse-transition>
            <div v-if="activeRealtimeLayers.includes('sensors')">
              <sensor-layer-selector :value="activeSensorMetric"
                @input="SELECT_SENSOR_METRIC"/>
            </div>
          </collapse-transition>
          <!--
          <b-form-checkbox value="gateways">Base Stations</b-form-checkbox><br/>
          <b-form-checkbox value="cameras">Cameras</b-form-checkbox><br/>
          <b-form-checkbox value="microphones">Microphones</b-form-checkbox>
          -->
        </b-form-checkbox-group>
      </sidebar-section>

      <sidebar-section v-if="geoJsonLayers && geoJsonLayers.length > 0"
        title="Extra Layers" :visible="true">
          <b-form-checkbox-group :checked="activeGeoJsonLayers" 
            @change="SELECT_GEOJSON_LAYERS">
            <b-form-checkbox v-for="layer in geoJsonLayers" :key="layer.key"
              :value="layer.key">
              {{layer.data.title || layer.key}}
            </b-form-checkbox>
          </b-form-checkbox-group>
      </sidebar-section>

    </div>
    <a id="sidebar-collapse" @click="toggleCollapsed">
      <div id="layer-icon">
        <font-awesome-icon icon="layer-group" size="1x"/>
      </div>
    </a>
  </div>
</template>

<script>
  import {mapState, mapMutations} from 'vuex';
  import SidebarSection from './SidebarSection';
  import SensorLayerSelector from './sensors/SensorLayerSelector';
  import CollapseTransition from '@/transitions/CollapseTransition';
  export default {
    name: 'Sidebar',
    components: {
      SidebarSection,
      SensorLayerSelector,
      CollapseTransition
    },
    computed: {
      ...mapState('map', [
        'datasets',
        'baseLayers',
        'overlayLayers',
        'activeOverlayLayers',
        'activeRealtimeLayers',
        'activeSensorMetric',
        'geoJsonLayers',
        'activeGeoJsonLayers',
      ]),
      datasetLayers() {
        let layers = [];
        for(let layer of this.baseLayers) {
          if(layer.dataset === this.activeDataset) {
            layers.push(layer);
          }
        }
        return layers;
      },
      datasetTypes() {
        let types = [];
        for(let layer of this.datasetLayers) {
          if(layer.dataset === this.activeDataset) {
            if(!types.includes(layer.type)) {
              types.push(layer.type);
            }
          }
        }
        return types;
      },
      datasetYears() {
        let years = [];
        for(let layer of this.datasetLayers) {
          if(!years.includes(layer.year) && layer.type === this.activeDatasetType) {
            years.push(layer.year);
          }
        }
        years.sort().reverse();
        return years;
      }
    },
    methods: {
      ...mapMutations('map', [
        'SET_BASE_LAYER', 
        'SELECT_OVERLAY_LAYERS',
        'SELECT_REALTIME_LAYERS',
        'SELECT_SENSOR_METRIC',
        'SELECT_GEOJSON_LAYERS',
      ]),
      toggleCollapsed() {
        this.collapsed = !this.collapsed;
        this.$emit('collapse', this.collapsed);
      },
      selectDataset(dataset) {
        if(!this.datasetTypes.includes(this.activeDatasetType)) {
          this.activeDatasetType = this.datasetTypes[0];
        }
        this.selectLayerType(this.activeDatasetType);
        this.updateBaseLayerSelection();
      },
      selectLayerType(type) {
        if(!this.datasetYears.includes(this.activeDatasetYear)) {
          let newYear = this.datasetYears[0];
          for(let year of this.datasetYears) {
            if(year > this.activeDatasetYear) {
              newYear = year;
            }
          }

          this.activeDatasetYear = newYear;
        }
        this.updateBaseLayerSelection();
      },
      selectYear(year) {
        this.updateBaseLayerSelection();
      },
      updateBaseLayerSelection() {
        for(let layer of this.baseLayers) {
          if(layer.dataset == this.activeDataset &&
            layer.type == this.activeDatasetType &&
            layer.year == this.activeDatasetYear) {
            this.SET_BASE_LAYER(layer.key);
            break;
          }
        }
      }
    },
    data() {
      return {
        activeDataset: 'naip',
        activeDatasetType: 'Visible',
        activeDatasetYear: 2018,
        collapsed: false
      }
    }
  }
</script>

<style scoped>
  #map-sidebar {
    width: 254px;
    background: #222;
    border-right: 2px solid #666;
    position: relative;
    transition: width 0.5s;
  }

  #sidebar-content {
    padding: 8px;
    overflow-x: hidden;
    width: 252px;
    position: absolute;
    bottom: 0;
    top: 0;
    overflow-y: auto;
  }

  #map-sidebar.collapsed {
    width: 0px;
  }

  #sidebar-content.collapsed {
  }

  #sidebar-collapse {
    cursor: pointer;
    display: flex;
    width: 48px;
    height: 48px;
    border: 2px solid #666;
    border-left: none;
    background: #222;
    z-index: 1000;
    position: absolute;
    bottom: 64px;
    right: -48px;
    border-radius: 0 8px 8px 0;
    justify-content: center;
    align-items: center;
    color: #bbb;
  }

  #layer-icon {
    font-size: 28px;
  }

  .custom-control-inline {
    font-size: 10pt;
  }
</style>
