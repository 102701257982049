<template>
  <div>
    <font-awesome-icon v-for="(item, index) in items" 
      :key="item.id" :icon="item.icon" class="tool-icon"
      :class="{selected: value !== undefined && item.component === value}"
      @click.prevent="setTab(item.component)" size="lg"/>
  </div>
</template>

<script>
  export default {
    name: 'TabBarWidgets',
    props: {
      items: Array,
      value: {
        type: Object
      }
    },
    computed: {
    },
    methods: {
      setTab(component) {
        if(component === this.value) {
          this.$emit('input', undefined);
        } else {
          this.$emit('input', component);
        }
      }
    },
    data() {
      return {
      }
    }
  }
</script>

<style scoped>
  .tool-icon {
    cursor: pointer;
    padding: 7px;
    width: 32px;
    height: 32px;
    text-align: center;
    margin-top: 2px;
    margin-bottom: -12px;
    margin-right: 4px;
    border-radius: 4px 4px 0 0;
    box-sizing: border-box;
  }

  .tool-icon.selected {
    background: #555;
    padding: 6px 4px 6px 4px;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #888;
  }
</style>
