/* DISCLAIMER: the following conversions are provided with ABSOLUTELY NO WARRANTY
 * and may have undergone minimal verification. 
 *
 * The Graphite conversion functions, in particular, may be approximate and for 
 * display purposes only. */

let metrics = [
    {
        name: "temperature",
        synonyms: ["bmp_temperature"],
        units: [
            {
                name: "°C",
                synonyms: ["C", "Deg C"]
            },
            {
                name: "°F",
                synonyms: ["F", "Deg F"],
                toSI: x => (x - 32) * 5 / 9,
                fromSI: x => x * 9 / 5 + 32,
                graphiteToSI: x => `scale(offset(${x},-32),0.5556)`,
                graphiteFromSI: x => `offset(scale(${x},1.8),32)`
            }
        ]
    },
    {
        name: "pressure",
        units: [
            {
                name: "kPa",
                synonyms: ["kilopascals"]
            },
            {
                name: "hPa",
                synonyms: ["hectopascals", "mbar", "millibar"],
                toSI: x => x / 10,
                fromSI: x => x * 10,
                graphiteToSI: x => `scale(${x},0.1)`,
                graphiteFromSI: x => `scale(${x},10)`
            },
            {
                name: "in Hg",
                synonyms: ["inches mercury", '" Hg', "inHg"],
                toSI: x => x * 3.3863886666667,
                fromSI: x => x / 3.3863886666667,
                graphiteToSI: x => `scale(${x},3.3864)`,
                graphiteFromSI: x => `scale(${x},0.2953)`
            },
            {
                name: "mm Hg",
                synonyms: ["millimeters mercury", "mmHg", "torr"],
                toSI: x => x / 760 * 101.325,
                fromSI: x => x / 101.325 * 760,
                graphiteToSI: x => `scale(${x},0.1333)`,
                graphiteFromSI: x => `scale(${x},7.5)`
            }
        ]
    },
    {
        name: "windspeed",
        units: [
            {
                name: "m/s",
                synonyms: ["meters/second", "m/sec", "meters per second"],
            },
            {
                name: "km/h",
                synonyms: ["kilometers/hour", "kilometers per hour", "kph"],
                toSI: x => x * 1000 / 3600,
                fromSI: x => x / 1000 * 3600,
                graphiteToSI: x => `scale(${x},0.2778)`,
                graphiteFromSI: x => `scale(${x},3.6)`
            },
            {
                name: "kn",
                synonyms: ["knots", "nautical miles/hour", "nautical miles per hour"],
                toSI: x => x * 0.514444,
                fromSI: x => x / 0.514444,
                graphiteToSI: x => `scale(${x},0.5144)`,
                graphiteFromSI: x => `scale(${x},1.9438)`
            },
            {
                name: "mph",
                synonyms: ["miles/hour", "miles per hour"],
                toSI: x => x * 0.44704,
                fromSI: x => x / 0.44704,
                graphiteToSI: x => `scale(${x},0.4470)`,
                graphiteFromSI: x => `scale(${x},2.2369)`
            }
        ]
    },
    {
        name: "rainfall",
        units: [
            {
                name: "mm",
                synonyms: "millimeters"
            },
            {
                name: "cm",
                synonyms: ["centimeters"],
                toSI: x => x * 10.0,
                fromSI: x => x / 10.0,
                graphiteToSI: x => `scale(${x},10.0)`,
                graphiteFromSI: x => `scale(${x},0.1)`
            },
            {
                name: "inches",
                synonyms: ["in"],
                toSI: x => x * 25.4,
                fromSI: x => x / 25.4,
                graphiteToSI: x => `scale(${x},25.4)`,
                graphiteFromSI: x => `scale(${x},0.03937)`
            }
        ]
    }
]

let graphiteOptimizedConversions = {
    "°C_°F": x => `offset(scale(${x},1.8),32)`,
    "°F_°C": x => `scale(offset(${x},-32),0.5556)`
}

class UnitManager {
    constructor() {
        this.lookupMap = new Map();
        this.metricMap = new Map();

        for(let metric of metrics) {
            this.metricMap.set(metric.name, metric);
            if(metric.synonyms !== undefined) {
                for(let synonym of metric.synonyms) {
                    this.metricMap.set(synonym, metric);
                }
            }

            for(let unit of metric.units) {
                unit.metric = metric;
                metric.lookupMap = new Map();
                this.lookupMap.set(unit.name, unit);
                metric.lookupMap.set(unit.name, unit);
                if(unit.synonyms !== undefined) {
                    for(let synonym of unit.synonyms) {
                        this.lookupMap.set(synonym, unit);
                        metric.lookupMap.set(synonym, unit);
                    }
                }
            }
        }
    }

    normalizeUnitName(unit, metric) {
        let lookupMap = this.lookupMap;
        if(metric !== undefined) {
            lookupMap = this.metricMap.get(metric);
        }

        if(lookupMap === undefined) {
            return unit;
        }

        let toUnit = lookupMap.get(unit);
        if(toUnit === undefined) {
            return unit;
        }

        return toUnit.name;
    }

    normalizeMetricName(metric) {
        let metricObj = this.metricMap.get(metric);
        if(metricObj === undefined) {
            return metric;
        }

        return metricObj.name;
    }

    convert(toUnit, sample) {
        let lookupMap = this.lookupMap;
        sample = Object.assign({}, sample);

        if(sample.metric !== undefined) {
            lookupMap = this.metricMap.get(sample.metric);
        }

        if(lookupMap === undefined) {
            return sample;
        }

        let fromUnit = lookupMap.get(sample.unit);
        let toUnitName = toUnit;
        toUnit = lookupMap.get(toUnit);

        if(fromUnit === undefined || toUnit === undefined) {
            /* can't do anything if we don't know where we're coming from
             * or where we're going */
            return sample;
        }
        
        if(typeof(fromUnit.toSI) === 'function') {
            sample.value = fromUnit.toSI(sample.value);
        }

        if(typeof(toUnit.fromSI) === 'function') {
            sample.value = toUnit.fromSI(sample.value);
        }

        sample.unit = toUnitName;
        return sample;
    }

    convertGraphite(toUnit, target) {
        let lookupMap = this.lookupMap;
        target = Object.assign({}, target);
        if(target.metric !== undefined) {
            lookupMap = this.metricMap.get(sample.metric);
        }

        if(lookupMap === undefined) {
            return target;
        }

        let fromUnit = lookupMap.get(target.unit);
        let toUnitName = toUnit;
        toUnit = lookupMap.get(toUnit);

        if(fromUnit === undefined || toUnit === undefined) {
            return target;
        }

        if(typeof(fromUnit.graphiteToSI) === 'function') {
            target.target = fromUnit.graphiteToSI(target.target);
        }

        if(typeof(toUnit.graphiteFromSI) === 'function') {
            target.target = toUnit.graphiteFromSI(target.target);
        }

        target.unit = toUnitName;
        return target;
    }
}

/*
const util = require('util');
let um = new UnitManager;
function test(toUnit, sample) {
    let converted = um.convert(toUnit, sample);
    console.log(util.inspect(sample) + " → " + toUnit + ": " + util.inspect(converted));
}

function testGraphite(toUnit, target) {
    let converted = um.convertGraphite(toUnit, target);
    console.log(util.inspect(target) + " → " + toUnit + ": " + util.inspect(converted));
}

test("°F", {unit: "°C", value: 0});
test("F", {unit: "Deg C", value: 0});
test("°C", {unit: "Deg C", value: 0});
test("°C", {unit: "°F", value: 32});

test("hPa", {unit: "kPa", value: 101.325});
test("torr", {unit: "kPa", value: 101.325});
test("inHg", {unit: "kPa", value: 101.325});
test("hPa", {unit: "torr", value: 760});

testGraphite("°F", {unit: "°C", target: "sensors.tidmarsh.{0x4001,0x4002}.temperature"});
testGraphite("F", {unit: "°C", target: "sensors.tidmarsh.{0x4001,0x4002}.temperature"});
testGraphite("inHg", {unit: "hPa", target: "sensors.tidmarsh.{0x4001,0x4002}.pressure"});
*/

let um = new UnitManager();
export default um;

