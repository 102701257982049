<template>
  <img class="camera-inner camera-still" :src="url" :alt="name"/>
</template>

<script>
  export default {
    name: 'StillCameraView',
    props: {
      url: String,
      name: String
    }
  }
</script>

<style scoped>
  .camera-still {
    width: 100%;
    position: absolute;
    top: -100%; left: 0; right: 0; bottom: -100%;
    margin: auto;
  }

</style>
