import {icon, point} from 'leaflet';

export default {
    sm_red: icon({
        iconUrl: require("@/assets/markers/marker_red.png"),
        iconRetinaUrl: require("@/assets/markers/marker_red_2x.png"),
        iconSize: point(12, 18),
        iconAnchor: point(6, 16),
        popupAnchor: point(0, -10)
    }),
    sm_yellow: icon({
        iconUrl: require("@/assets/markers/marker_yellow.png"),
        iconRetinaUrl: require("@/assets/markers/marker_yellow_2x.png"),
        iconSize: point(12, 18),
        iconAnchor: point(6, 16),
        popupAnchor: point(0, -10)
    }),
    sm_green: icon({
        iconUrl: require("@/assets/markers/marker_green.png"),
        iconRetinaUrl: require("@/assets/markers/marker_green_2x.png"),
        iconSize: point(12, 18),
        iconAnchor: point(6, 16),
        popupAnchor: point(0, -10)
    }),
    flag: icon({
        iconUrl: require("@/assets/markers/marker_flag.png"),
        iconRetinaUrl: require("@/assets/markers/marker_flag_2x.png"),
        iconSize: L.point(12, 18),
        iconAnchor: L.point(3, 15),
        popupAnchor: L.point(4, -12)
    }),
    node_mk2: icon({
        iconUrl: require("@/assets/markers/node_mk2.png"),
        iconRetinaUrl: require("@/assets/markers/node_mk2@2x.png"),
        iconSize: L.point(24, 39),
        iconAnchor: L.point(16, 38),
        popupAnchor: L.point(0, -36)
    }),
    gateway: icon({
        iconUrl: require("@/assets/markers/gateway.png"),
        iconRetinaUrl: require("@/assets/markers/gateway@2x.png"),
        iconSize: L.point(24,39),
        iconAnchor: L.point(3,40),
        popupAnchor: L.point(0,-38)
    }),
    mic: icon({
        iconUrl: require("@/assets/markers/mic.png"),
        iconRetinaUrl: require("@/assets/markers/mic@2x.png"),
        iconSize: L.point(24,39),
        iconAnchor: L.point(6,40),
        popupAnchor: L.point(0,-37)
    }),
}
