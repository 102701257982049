<template>
  <li class="camera-thumbnail" @click="setActive"
    :class="{active}" :style="{background: 'url(' + camera.thumb + ')'}">
    <div v-if="camera.caption !== undefined" class="camera-caption" >
      {{camera.caption}}
    </div>
    <div v-if="tag !== undefined" class="camera-tag" :class="tag.class">{{tag.text}}</div>
  </li>
</template>

<script>
  export default {
    name: 'CameraThumbnail',
    props: {
      camera: Object,
      active: Boolean
    },
    computed: {
      tag() {
        if(this.camera.hls !== undefined && this.camera.online) {
          return {
            text: "LIVE VIDEO"
          };
        } else if(this.camera.online) {
          return {
            text: "LIVE"
          };
        } else if(!this.camera.online) {
          return {
            text: "Offline",
            class: "tag-offline"
          };
        }
      }
    },
    methods: {
      setActive() {
        this.$emit('click');
      }
    }
  }
</script>

<style>
  .camera-thumbnail {
    display: inline-block;
    list-style-type: none;
    margin: 10px;
    padding: 0px;
    position: relative;
    width: 144px;
    height: 81px;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .camera-caption {
    position: absolute;
    bottom: 4px;
    left: 4px;
    z-index: 1000;
    font-size: 9pt;
    font-weight: bold;
    display: block;
    background: rgba(0,0,0,0.5);
    padding: 2px;
    border-radius: 2px;
  }

  .camera-tag {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1000;
    font-size: 9pt;
    font-weight: bold;
    display: block;
    background: rgba(255,0,0,0.5);
    padding: 2px;
    border-radius: 2px;
  }

  .tag-offline {
    background-color: gray;
  }


  .camera-thumbnail.active {
    border: 2px solid red;
    margin: 8px;
  }

  @media (max-width: 600px) {
    .camera-thumbnail {
      width: 80px;
      height: 60px;
    }
  }
</style>
