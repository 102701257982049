<template>
  <ul>
    <li v-for="item of items">
      <router-link :to="'/data/' + item.id" :key="item.id">{{item.title}}</router-link>
    </li>
  </ul>
</template>

<script> 
  export default {
    name: 'NavTabs',
    props: {
      items: Array
    }
  };
</script>

<style scoped>
  ul {
    margin: 0px;
    padding: 0px;
    border-bottom: 2px solid #777;
  }

  ul li {
    display: inline-block;
    padding: 0px;
    margin: 0px;
  }

  a {
    display: block;
    padding: 8px;
    padding-left: 18px;
    padding-right: 18px;
    text-decoration: none;
    color: #fff;
  }

  a:hover {
    background: #666;
  }

  a.router-link-active {
    background: #777;
    font-weight: bold;
  }
</style>
