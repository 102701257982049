<template>
  <l-geo-json v-if="geodata !== undefined" :geojson="geodata" :options="options"/>
</template>

<script>
  import Vue from 'vue';
  import {L, LGeoJson} from 'vue2-leaflet';
  import {circleMarker, marker} from 'leaflet';
  import icons from '@/common/mapmarkers';
  import GeoJsonPopup from '@/components/map/GeoJsonPopup';
  import axios from 'axios';

  export default {
    name: 'GeoJsonLayer',
    props: {
      geojson: Object,
      url: String,
    },
    components: {
      LGeoJson
    },
    computed: {
      geodata() {
        if(this.geojson !== undefined) return this.geojson;
        if(this.fetchedData !== undefined) return this.fetchedData;
        return undefined;
      },
    },
    mounted() {
      if(this.url !== undefined) {
        this.fetchData(this.url);
      }
    },
    methods: {
      async fetchData(url) {
        try {
          let result = await axios.get(url);
          this.fetchedData = result.data;
        } catch(e) {
          console.log(`error fetching geojson: ${e.toString()}`);
        }
      }
    },
    watch: {
      url(to, from) {
        if(to !== undefined) {
          this.fetchData(to);
        }
      },
    },
    data() {
      return {
        fetchedData: undefined,
        options: {
          pointToLayer(feature, coordinates) {
            let properties = feature.properties || {};
            if(properties.icon !== undefined &&
              icons[properties.icon] !== undefined) {
              return marker(coordinates, 
                {icon: icons[properties.icon]});
            } else {
              return circleMarker(coordinates, {
                radius: 4,
                fillColor: "#ff7800",
                color: "#000",
                weight: 2,
                opacity: 1,
                fillOpacity: 0.8
              });
            }
          },
          onEachFeature(feature, layer) {
            let properties = feature.properties || {};
            if(properties.title === undefined) return;
            let PopupContent = Vue.extend(GeoJsonPopup);
            let popup = new PopupContent({
              propsData: {
                type: feature.geometry.type,
                properties: properties
              }
            });
            layer.bindPopup(popup.$mount().$el);
          },
          style(feature) {
            if(feature.properties !== undefined && 
              feature.properties.style !== undefined) {
              return feature.properties.style;
            }
          },
        }
      }
    }
  }
</script>

<style scoped>

</style>
