import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import {
    LayoutPlugin,
    FormPlugin,
    FormGroupPlugin,
    FormRadioPlugin,
    FormSelectPlugin,
    ButtonPlugin,
    PopoverPlugin,

    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormSelect,
    BSpinner,
    BAlert,
} from 'bootstrap-vue';

Vue.use(LayoutPlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(ButtonPlugin);
Vue.use(PopoverPlugin);


Vue.component('b-form-checkbox', BFormCheckbox);
Vue.component('b-form-checkbox-group', BFormCheckboxGroup);
Vue.component('b-form-group', BFormGroup);
Vue.component('b-spinner', BSpinner);
Vue.component('b-alert', BAlert);

//Vue.use(BootstrapVue)
