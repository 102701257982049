<template>
  <div>
    <h2>{{ title }}</h2>
    <ul class="thumbs">
      <media-thumbnail v-for="item of items"
        :key="item.id"
        :caption="item.caption"
        :thumb="item.thumb || 'img/thumb_' + item.id + '.jpg'"
        :href="item.href"/>
    </ul>
  </div>
</template>

<script>
  import MediaThumbnail from '@/components/MediaThumbnail.vue';
  export default {
    name: 'MediaGallery',
    props: {
      title: String,
      items: Array
    },
    components: {
      MediaThumbnail
    }
  }
</script>

<style scoped>
  .thumbs {
    padding-left: 8px;
  }
</style>
