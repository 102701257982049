import io from '@/socket-instance';

export default {
    namespaced: true,
    state: {
        sites: [
        ],
        error: null,
        siteLoaded: false,
        site: {},
        activeMetric: 'temperature',
        plotSpan: {
            from: '-1days',
            until: 'now'
        },
        audio: {
            muted: true,
            source: null
        }
    },
    mutations: {
        START_LOADING(state) {
            state.error = null;
            state.siteLoaded = false;
        },
        SET_ERROR(state, message) {
            state.error = message;
        },
        SET_SITE_LIST(state, sites) {
            state.sites = sites;
        },
        SET_SITE(state, site) {
            state.site = site;
            state.siteLoaded = true;
        },
        SET_PLOT_SPAN(state, span) {
            state.plotSpan.from = span.from;
            state.plotSpan.until = span.until;
        },
        SET_AUDIO_MUTE(state, muted) {
            state.audio.muted = muted;
        },
        SET_AUDIO_SOURCE(state, source) {
            state.audio.source = source;
        },
        SOCKET_CONNECT(state) {
            if(state.siteLoaded) {
                io.emit('joinSite', state.site.id);
            }
        },
        SOCKET_CAMERA_UPDATE(state, frame) {
            for(let i=0; i<state.site.cameras.length; i++) {
                let camera = state.site.cameras[i];
                if(camera.id == frame.id) {
                    Object.assign(camera, frame);
                }
            }
        },
        SOCKET_DEVICE_UPDATE(state, devices) {
            if(state.site.devices === undefined) return;
            for(let key of Object.keys(devices)) {
                let updatedDevice = devices[key];
                let device = state.site.devices[key];

                if(device === undefined) {
                    state.site.devices[key] = updatedDevice;
                    continue;
                }

                device.last_seen = updatedDevice.last_seen;
                if(device.metrics === undefined) {
                    device.metrics = {};
                }

                if(updatedDevice.metrics === undefined) {
                    continue;
                }

                for(let metricName of Object.keys(updatedDevice.metrics)) {
                    let updatedMetric = updatedDevice.metrics[metricName];
                    if(device.metrics[metricName] === undefined) {
                        device.metrics[metricName] = updatedMetric;
                    } else {
                        device.metrics[metricName].value = updatedMetric.value;
                        device.metrics[metricName].timestamp = updatedMetric.timestamp;
                    }
                }
            }
        },
        SOCKET_SITE_UPDATE(state, site) {
            state.site = site;
            state.siteLoaded = true;
        }
    },
    actions: {
        loadSite({commit}, args) {
            commit('START_LOADING');
            io.emit('loadSite', args.site, (result) => {
                if(result.error !== undefined) {
                    commit('SET_ERROR', result.error);
                } else {
                    commit('SET_SITE', result.site);
                }
            });
        },
        listSites({commit}, args) {
            console.log('listSitesCalled');
            io.emit('listSites', (sites) => {
                console.log('siteList ', sites);
                if(sites.error === undefined) {
                    commit('SET_SITE_LIST', sites);
                } else {
                    commit('SET_ERROR', sites.error);
                }
            });
        }
    }
}

