<template>
  <div>
    <b>{{title}}</b><br/>
    {{description}}
  </div>
</template>

<script>
  export default {
    name: 'GeoJsonPopup',
    props: {
      type: {type: String, default: ""},
      properties: {type: Object, default: {}},
    },
    computed: {
      title() {
        return this.properties.title || "";
      },
      description() {
        return this.properties.description || "";
      }
    }
  }
</script>

<style scoped>

</style>
