<template>
  <div id="map-view">
    <top-nav/>
    <div id="map-app">
      <sidebar @collapse="sidebarCollapsed = $event"/>
      <map-viewport :sidebar-collapsed="sidebarCollapsed"/>
    </div>
  </div>
</template>

<script>
  import TopNav from '@/components/TopNav';
  import Sidebar from '@/components/map/Sidebar';
  import MapViewport from '@/components/map/Map';
  import {mapActions} from 'vuex';

  export default {
    name: 'MapView',
    components: {
      TopNav,
      Sidebar,
      MapViewport
    },
    methods: {
      ...mapActions('map', ['loadGeoJsonLayers']),
      loadData() {
        if(this.$route.query.layers !== undefined) {
          let layers = this.$route.query.layers.split(',');
          console.log('loadLayers: ', layers);
          this.loadGeoJsonLayers(layers);
        }
      }
    },
    mounted() {
      this.loadData();
    },
    data() {
      return {
        sidebarCollapsed: false
      }
    },
    watch: {
      "$route"(to, from) {
        this.loadData();
      }
    },
  }
</script>

<style>
  body {
      background: #222;
      margin: 0;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      color: white;
  }

  a {
      color: #c33;
      outline: none;
  }

  #map-view {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  #map-app {
    flex-grow: 2;
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 600px) {
    body {
      font-size: 10pt;
    }
  }
</style>
