<template>
  <div class="wx-overlay">
    <div class="wx-widget" v-if="temperature !== undefined">
      <div class="wx-inner">
        <div class="wx-lg">{{temperature.value}} {{temperature.unit}}</div>
      </div>
    </div>
    <div class="wx-widget" v-if="humidity !== undefined">
      <div class="wx-inner">
        <div class="wx-lg">{{humidity.value}} {{humidity.unit}}</div>
        <div class="wx-caption">Humidity</div>
      </div>
    </div>
    <div class="wx-widget wx-wind" v-if="windspeed !== undefined">
        <div class="wx-wind-ind" v-if="winddir !== undefined" :style="winddir"></div>
        <div class="wx-wind-value">
          <div class="wx-lg">{{windspeed.value}}</div>
          <div class="wx-caption">{{windspeed.unit}}</div>
        </div>
      </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import units from '@/units.js';

  export default {
    name: 'WxOverlay',
    props: {
      config: Object
    },
    computed: {
      ...mapState('data', {
        devices: state => state.site.devices
      }),
      ...mapState('units', {
        preferredUnits: state => state.preferredUnits
      }),
      temperature() {
        return this.getFormattedMetric('temperature', {fixed: 1});
      },
      humidity() {
        return this.getFormattedMetric('humidity', {fixed: 0});
      },
      windspeed() {
        return this.getFormattedMetric('windspeed', {fixed: 0});
      },
      winddir() {
        let metric = this.getMetric('winddir');
        if(metric === undefined) return;
        return `transform: scale(0.9) rotate(${metric.value}deg);`;
      }
    },
    methods: {
      deviceByAlias(alias) {
        for(let key of Object.keys(this.devices)) {
          if(this.devices[key].alias == alias) {
            return this.devices[key];
          }
        }
      },
      getMetric(name) {
        if(this.config[name] === undefined) return;
        let config = this.config[name];
        let device = this.deviceByAlias(config.deviceAlias);
        if(device === undefined) return;
        let metric = device.metrics[config.metric];
        return metric;
      },
      getFormattedMetric(name, opts) {
        opts = Object.assign({
          fixed: 1
        }, opts);
        let metric = this.getMetric(name);
        if(metric === undefined) return;

        let sample = units.convert(this.preferredUnits[name], {
          unit: metric.unit, value: metric.value
        });
        return {
          value: sample.value.toFixed(opts.fixed),
          unit: sample.unit
        };
      }
    }
  }
</script>

<style>
  .wx-overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 150px;
    z-index: 1000;
    padding: 16px;
    max-width: 20vw;
  }

  .wx-widget {
    background: rgba(0,0,0,0.5);
    border-radius: 8px;
    text-align: center;
    margin-bottom: 16px;
    position: relative;
  }

  .wx-inner {
    padding: 16px;
    position: relative;
  }

  .wx-wind {
  }

  .wx-wind:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .wx-wind-ind {
    background-image: url('../assets/winddir_ind.svg');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
  }

  .wx-wind-value {
    z-index: 1100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
  }

  .wx-lg {
    font-size: 24pt;
  }

  @media (max-width: 700px) {
    .wx-overlay {
    }

    .wx-inner {
      padding: 6px;
      font-size: 70%;
    }

    .wx-widget {
      margin-bottom: 8px;
    }

    .wx-lg {
      font-size: 12pt;
    }

  }
</style>
