<template>
  <div class="unit-bar">
    <div class="unit-bar-inner">
      <radio-button v-for="(items, metric) in units" :key="metric" :items="items" 
        :value="preferredUnits[metric]"
        @input="setUnit(metric, $event)"/>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import RadioButton from '@/components/RadioButton.vue';

  export default {
    name: 'UnitBar',
    components: {
      RadioButton
    },
    computed: {
      ...mapState('units', {
        preferredUnits: state => state.preferredUnits
      })
    },
    methods: {
      setUnit(metric, unit) {
        this.$store.commit('units/SET_PREFERRED_UNIT', {
          metric, unit
        });
      }
    },
    data() {
      return {
        units: {
          temperature: ['°C', '°F'],
          rainfall: ['inches', 'mm', 'cm'],
          windspeed: ['km/h', 'm/s', 'mph', 'knots'],
          pressure: ['hPa', 'in Hg', 'mm Hg']
        }
      }
    }
  }
</script>

<style scoped>
  .unit-bar {
    background: #555;
  }
  .unit-bar-inner {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 8px;
  }
</style>
