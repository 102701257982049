import Vue from 'vue';
import Vuex from 'vuex';

import home from './store/home';
import data from './store/data';
import units from './store/units';
import map from './store/map';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        graphiteBase: 'https://tidmarsh.media.mit.edu/graphite/render',
        graphiteMetricBase: 'sensors.tidmarsh.',
        graphiteSalt: new Date()
    },
    mutations: {
        UPDATE_GRAPHITE_SALT(state) {
            state.graphiteSalt = new Date()
        }
    },
    actions: {

    },
    modules: {
        home,
        data,
        units,
        map
    }
})
