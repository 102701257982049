export let prefabPlots = {
    temperature: {
        title: 'Temperature',
        unit: '°C',
        unitType: 'temperature',
        metric: 'temperature',
        hideLegend: false
    },
    humidity: {
        title: 'Relative Humidity',
        unit: '%',
        unitType: 'humidity',
        metric: 'humidity',
        yMin: 0,
        yMax: 100,
        hideLegend: false
    },
    pressure: {
        title: 'Atmospheric Pressure',
        unit: 'hPa',
        unitType: 'pressure',
        metric: 'pressure',
        yMin: 980,
        yMax: 1040,
        hideLegend: false
    },
    battery: {
        title: 'Battery Voltage',
        unit: 'V',
        unitType: 'voltage',
        metric: 'battery_voltage',
        yMin: 2,
        yMax: 4,
        hideLegend: false
    },
    visible: {
        title: 'Visible Light',
        unit: 'lux',
        unitType: 'illuminance',
        metric: 'light_als',
        yMin: 0.1,
        yMax: 100000,
        hideLegend: false
    },
    infrared: {
        title: 'Infrared',
        metric: 'light_ir_dark',
        unit: 'uncalibrated',
        yMin: 0.1,
        yMax: 100000,
        hideLegend: false
    },
    UVA: {
        title: 'UV A',
        metric: 'light_uva',
        unit: 'index',
        yMin: 0,
        yMax: 20,
        hideLegend: false
    },
    UVB: {
        title: 'UV B',
        metric: 'light_uvb',
        unit: 'index',
        yMin: 0,
        yMax: 20,
        hideLegend: false
    },
    vwc: {
        title: 'Soil Moisture',
        metric: 'soil_moisture',
        unit: 'mV',
        unitType: 'voltage',
        yMin: 300,
        yMax: 1000,
        hideLegend: false
    },
    vwc_5cm: {
        title: 'Soil Moisture (5cm)',
        metric: 'soil_moisture_5cm',
        unit: 'mV',
        unitType: 'voltage',
        yMin: 300,
        yMax: 1000,
        hideLegend: false
    },
    vwc_15cm: {
        title: 'Soil Moisture (15cm)',
        metric: 'soil_moisture_15cm',
        unit: 'mV',
        unitType: 'voltage',
        yMin: 300,
        yMax: 1000,
        hideLegend: false
    },
    soil_temperature: {
        title: 'Soil Temperature',
        metric: 'soil_temperature',
        unit: '°C',
        unitType: 'temperature',
        hideLegend: false,
        yMax: 35 
    },
    soil_temperature_5cm: {
        title: 'Soil Temperature (5cm)',
        metric: 'soil_temperature_5cm',
        unit: '°C',
        unitType: 'temperature',
        hideLegend: false,
        yMax: 35 
    },
    soil_temperature_15cm: {
        title: 'Soil Temperature (15cm)',
        metric: 'soil_temperature_15cm',
        unit: '°C',
        unitType: 'temperature',
        hideLegend: false,
        yMax: 35

    }
};
