<template>
  <main-view>
    <timelapse-viewer url="/timelapse/herring/300x/2019/playlist.m3u8"/>
  </main-view>
</template>

<script>
  import TimelapseViewer from '@/components/TimelapseViewer';

  export default {
    name: 'TimelapseTest',
    components: {
      TimelapseViewer,
    },
  }
</script>

<style scoped>
  .camera-container {
    width: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #333;
    padding-top: 56.25%;
    overflow: hidden;
  }

</style>
