<template>
  <div class="sidebar-section">
    <div class="sidebar-title" @click="toggle">
      <font-awesome-icon icon="caret-right" class="mr-2 sidebar-icon"
        :class="{opened: isOpen}"/>{{title}}</div>
    <collapse-transition>
      <div v-if="isOpen">
        <div class="sidebar-content">
          <slot></slot>
        </div>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
  import CollapseTransition from '@/transitions/CollapseTransition';
  export default {
    name: 'SidebarSection',
    props: {
      title: String,
      visible: {type: Boolean, default: false}
    },
    components: {
      CollapseTransition
    },
    computed: {
      icon() {
        return this.visible? 'caret-down' : 'caret-right';
      }
    },
    methods: {
      toggle() {
        this.opened = !this.isOpen;
      }
    },
    computed: {
      isOpen() {
        return this.opened === undefined? this.visible : this.opened;
      }
    },
    data() {
      return {
        opened: undefined
      }
    }
  }
</script>

<style scoped>
  .sidebar-section {
    border: 1px solid #555;
    border-radius: 4px;
    padding: 0px 8px 0px 8px;
    margin-bottom: 4px;
  }

  .sidebar-icon {
    transition: transform 0.25s;
  }

  .sidebar-icon.opened {
    transform: rotate(90deg);
  }

  .sidebar-title {
    font-size: 18pt;
    font-weight: bold;
    cursor: pointer;
  }

  .sidebar-content {
    margin-left: 16px;
    padding-bottom: 8px;
  }
</style>
