<template>
  <div id="map">
    <l-map :center="center" :zoom="zoom" :maxZoom="maxZoom" ref="map">
      <l-tile-layer :url="baseLayer.url" :tms="true" layer-type="base"
        :attribution="formatAttrib(baseLayer)" :key="baseLayer.key"
        :options="baseLayer.options"/>
      <l-tile-layer v-for="layer in overlayTileMaps" :url="layer.url" :tms="true"
        layer-type="overlay" :key="layer.key" :options="layer.options"
        :attribution="formatAttrib(layer)"/>
      <geo-json-layer v-for="layer in overlayGeoJsonMaps" :url="layer.url" 
        :key="layer.key" 
        :attribution="formatAttrib(layer)"/>
      <l-control-scale position="bottomleft"/>
      <l-feature-group name="Sensor Nodes" layer-type="overlay" 
        v-if="activeRealtimeLayers.includes('sensors')">
        <sensor-marker v-for="sensor in filteredSensors" :key="sensor.id" 
          :sensor="sensor"/>
      </l-feature-group>
      <geo-json-layer v-for="layer in geoJson" :geojson="layer.data"
        :key="layer.key"/>
      <grad-legend v-if="linearScale !== undefined" :scale="linearScale"></grad-legend>
    </l-map>
  </div>
</template>

<script>
  import {L, LMap, LTileLayer, LControlScale, LControlZoom, LFeatureGroup} from 'vue2-leaflet';
  import {mapState, mapActions} from 'vuex';
  import GradLegend from './GradLegend';
  import SensorMarker from './markers/SensorMarker';
  import {scales} from '@/scales';
  import GeoJsonLayer from './GeoJsonLayer';

  export default {
    name: 'Map',
    components: {
      LMap,
      LTileLayer,
      LControlScale,
      LControlZoom,
      LFeatureGroup,
      SensorMarker,
      GradLegend,
      GeoJsonLayer,
    },
    props: {
      sidebarCollapsed: Boolean
    },
    mounted() {
      this.loadMap();
    },
    beforeDestroy() {
      this.leaveMap();
    },
    computed: {
      ...mapState('map', [
        'baseLayers',
        'activeBaseLayer',
        'overlayLayers',
        'activeOverlayLayers',
        'activeRealtimeLayers',
        'activeSensorMetric',
        'center',
        'zoom',
        'maxZoom',
        'sensors',
        'geoJsonLayers',
        'activeGeoJsonLayers',
      ]),
      baseLayer() {
        for(let layer of this.baseLayers) {
          if(layer.key === this.activeBaseLayer) return layer;
        }
      },
      overlayMaps() {
        let layers = [];
        for(let key of this.activeOverlayLayers) {
          for(let layer of this.overlayLayers) {
            if(layer.key === key) {
              layers.push(layer);
              break;
            }
          }
        }
        return layers;
      },
      overlayTileMaps() {
        return this.overlayMaps.filter(map => map.url.endsWith('.png') ||
          map.url.endsWith('.jpg'));
      },
      overlayGeoJsonMaps() {
        return this.overlayMaps.filter(map => map.url.endsWith('.geojson') ||
          map.url.endsWith('.json'));
      },
      geoJson() {
        if(!this.geoJsonLayers) return [];
        let layers = [];
        for(let key of this.activeGeoJsonLayers) {
          for(let layer of this.geoJsonLayers) {
            if(layer.key === key) {
              layers.push(layer);
              break;
            }
          }
        }
        return layers;
      },
      linearScale() {
        let scale = scales[this.activeSensorMetric];
        return scale !== undefined && scale.type === 'linear'? scale : undefined;
      },
      filteredSensors() {
        return Object.values(this.sensors).filter((sensor) => {
          if(new Date() - new Date(sensor.last_seen) > 3600000) return false;
          return true;
        });
      },
    },
    methods: {
      ...mapActions('map', [
        'loadMap',
        'leaveMap'
      ]),
      formatAttrib(layer) {
        if(layer.attrib === undefined) return undefined;
        if(layer.attribUrl !== undefined) {
          return `<a href="${layer.attribUrl}">${layer.attrib}</a>`;
        } else {
          return layer.attrib;
        }
      },
    },
    watch: {
      sidebarCollapsed(wasCollapsed, isCollapsed) {
        let ivl = setInterval(() => {
          this.$refs.map.mapObject.invalidateSize();
        }, 33);
        setTimeout(() => {
          clearInterval(ivl);
        }, 500);
      }
    }
  }
</script>

<style scoped>
  #map {
    flex-grow: 2;

  }
</style>
