import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import {
    faBars,
    faPlayCircle,
    faPlay,
    faPause,
    faStepForward,
    faFastForward,
    faStepBackward,
    faFastBackward,
    faCalendarDay,
    faCompress,
    faVideoSlash,
    faChevronRight,
    faChevronDown,
    faCog,
    faCalendarAlt,
    faTimes,
    faVolumeMute,
    faVolumeUp,
    faLayerGroup,
    faCaretRight,
    faArrowRight,
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faBars,
    faPlayCircle,
    faPlay,
    faPause,
    faCompress,
    faStepForward,
    faFastForward,
    faStepBackward,
    faFastBackward,
    faCalendarDay,
    faVideoSlash,
    faChevronRight,
    faChevronDown,
    faCog,
    faCalendarAlt,
    faTimes,
    faVolumeMute,
    faVolumeUp,
    faLayerGroup,
    faCaretRight,
    faArrowRight
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('fa-icon', FontAwesomeIcon);

