<template>
  <div class="error">
    <h3><slot></slot></h3>
    <p>
      {{ error }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'ErrorMessage',
    props: {
      error: String
    }
  };
</script>

<style scoped>
  .error {
    margin: 16px;
    padding: 16px;
    border: 2px solid #900;
    border-radius: 8px;
    background: #c99;
    color: #900;
  }
</style>
