import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue';
import './plugins/bootstrap-vue'
import App from './App.vue';
import router from './router';
import store from './store';
import VueSocketIO from 'vue-socket.io-extended';
import io from './socket-instance';
import './icons';

Vue.config.productionTip = false;

Vue.use(VueSocketIO, io, {store});

import MainView from '@/templates/MainView.vue';
import PageTemplate from '@/components/PageTemplate.vue';
Vue.component('main-view', MainView);
Vue.component('page-template', PageTemplate);

import 'leaflet/dist/leaflet.css';

let v = new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')


setInterval(() => {
    store.commit('UPDATE_GRAPHITE_SALT')
}, 300000);
