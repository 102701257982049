<template>
  <main-view>
    <transition name="fade">
      <error-message v-if="error !== null" :error="error">
        Sorry, this sensor site could not be loaded.
      </error-message>
      <data-display v-else-if="loaded"/>
      <loading-spinner v-else/>
    </transition>
  </main-view>
</template>

<script>
  import DataDisplay from '@/views/DataDisplay.vue';
  import NavTabs from '@/components/NavTabs.vue';
  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import ErrorMessage from '@/components/ErrorMessage.vue';
  import {mapState} from 'vuex';

  export default {
    name: 'Data',
    components: {
      NavTabs,
      LoadingSpinner,
      ErrorMessage,
      DataDisplay
    },
    computed: {
      ...mapState('data', {
        error: state => state.error,
        loaded: state => state.siteLoaded === true
      })
    },
    created() {
      this.loadSite(this.$route.params.site);
      this.$store.dispatch('data/listSites');
    },
    methods: {
      loadSite(siteID) {
        this.$store.dispatch('data/loadSite', {site: siteID});
      }
    },
    watch: {
      '$route' (to, from) {
        let newSiteID = to.params.site;
        this.loadSite(newSiteID);
      }
    }
  }
</script>

<style>
  .fade-enter-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
