<template>
  <li>
    <a :href="href">
      <img :src="thumb" :alt="caption"/>
      <span class="caption">{{caption}}</span>
    </a>
  </li>
</template>

<script>
  export default {
    name: 'MediaThumbnail',
    props: {
      href: String,
      thumb: String,
      caption: String
    }
  }
</script>

<style scoped>
  li {
    width: 240px;
    padding: 8px;
    display: inline-block;
    background: #333;
    text-align: center;
    margin: 8px;
  }

  a {
    color: white;
    text-decoration: none;
  }

  img {
    width: 224px;
    height: auto;
  }

  .caption {
    font-size: 10pt;
    display: block;
    width: 100%;
    text-align: center;
  }
</style>

