<template>
  <div class="camera-inner">
    <video playsinline="true" :muted="muted" ref="player"></video>
    <div class="video-playoverlay" @click="playVideo" :class="{paused}">
      <font-awesome-icon icon="play-circle" class="play-icon"/>
    </div>
  </div>
</template>

<script>
  import Hls from 'hls.js/dist/hls.light.min.js';
  import {mapState} from 'vuex';

  export default {
    name: 'HLSCameraView',
    props: {
      url: String
    },
    methods: {
      loadVideo(url) {
        if(Hls.isSupported()) {
          this.hls.loadSource(url);
        } else {
          this.$refs.player.src = url;
        }
      },
      playVideo() {
        this.$refs.player.play();
        setTimeout(() => {
          this.paused = this.$refs.player.paused;
        }, 500);
      }
    },
    computed: {
      ...mapState('data', {
        audio: state => state.audio
      }),
      muted() {
        if(this.audio.source == null) return true;
        return this.audio.muted ||  
          this.audio.source.id != "hls";
      }
    },
    mounted() {
      let self=this;
      if(Hls.isSupported()) {
        this.hls.attachMedia(this.$refs.player);
        this.hls.on(Hls.Events.MANIFEST_PARSED, function() {
          self.playVideo();
        });
      } else {
        this.$refs.player.addEventListener('loadedmetada', function() {
          self.playVideo();
        });
      }
      this.loadVideo(this.$props.url);
    },
    destroyed() {
      this.hls.destroy();
    },
    data() {
      return {
        ready: false,
        paused: true,
        hls: new Hls({
          capLevelToPlayerSize: true
        })
      }
    }, 
    watch: {
      url(to, from) {
        this.loadVideo(to);
      }
    }
  }
</script>

<style scoped>
  .camera-inner {
  }

  video {
    width: 100%;
  }

  .video-playoverlay {
    display: none;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.4);
    z-index: 1000;
    align-items: center;
    justify-content: center;
  }

  .video-playoverlay.paused {
    display: flex;
  }

  .play-icon {
    font-size: 48pt;
  }
</style>
