<template>
  <main-view>
    <page-template banner="img/banner_home.jpg">
      <p>
        Two centuries of cranberry farming transformed thousands of acres of
        natural wetlands into artificially elevated agricultural fields in
        Southeastern Massachusetts. Today, scientists understand that this
        transformation came at a high cost to the environment. Now that the
        economics of the cranberry industry have made it less advantageous to
        farm in the region, the time is right for policies and funding that
        encourage farmers to consider restoration. <a
        href="https://www.livingobservatory.org">Living Observatory</a> was
        founded in 2011 as a learning collaborative of scientists, artists, and
        wetland restoration practitioners to document, interpret, and reveal
        aspects of change as it occurs prior to, during, and following the
        first few restoration projects in Massachusetts.
      </p>
      <p>
        Several projects are currently underway and researchers of many
        disciplines are investigating what changes can be observed to quantify
        the benefits and limitations of cranberry bog restoration. One such
        project is being carried out by researchers at the MIT Media Lab's <a
        href="https://resenv.media.mit.edu">Responsive Environments Group</a>.
        They have developed and implemented a sensor network that documents
        ecological processes and allows people to experience the data at
        different spatial and temporal scales. Small, distributed, low-power
        sensor devices capture climate, soil, water, and other environmental
        data, while others stream audio from high in the trees and underwater.
        Visit any time from dawn till dusk and again after midnight; if you're
        lucky you might just catch an April storm, a flock of birds, or an army
        of frogs.
      </p>
      <h3>More about the Responsive Environments Group</h3>
      <p>
        Many current projects in the group
        are making use of the Tidmarsh site <router-link to="/data">and
        the data</router-link>.  The flagship project is a cross-reality sensor
        data browser constructed using the Unity game engine to
        experiment with presence and multimodal sensory experiences.
        We're looking for new ways to explore and experience data
        about the environment.  Built on LIDAR-scanned terrain data,
        the virtual Tidmarsh experience integrates real-time data from
        the sensor networks with real-time audio streams and other
        media.  The soundtrack is based on real-time sensor
        data—flashes and ukulele notes occur when new data comes from
        each sensor.  The music is driven by the sensor readings:
        higher pitches indicate warmer temperatures, for example.
      </p>

      <h3>Navigating this Site</h3>
      <p>
        Using the links at the top of this page, you can view sensor <router-link
        to="/data">data</router-link>, see realtime camera feeds, and listen to live audio from
        several locations at Tidmarsh.  Each cluster of sensors has its own page.  A <router-link
        to="/map">map view</router-link> also lets you explore Tidmarsh and the surrounding area
        overlaid with live sensor data and other data layers.
      </p>

      <p>
        The <router-link to="/data/herring"><em>Herring</em></router-link> site overlooks the stream
        channel, and you might spot river herring running in the spring.  Other wildlife, including
        great blue herons, muskrats, otters, and deer make frequent appearances.  The sensors nearby
        are outfitted with soil moisture probes that we are using to study the effects of
        microtopography (a restoration technique) at Tidmarsh.
      </p>

      <p>
        The <router-link to="/data/impoundment"><em>Impoundment</em></router-link> site is the
        location of a former reservoir.  Here, we have cameras and a large array of microphones.
        You can listen 24/7 (hover over the video and click the speaker to unmute).  We are
        exploring ways that we can use live audio and our years-long archive of recordings to track
        the return of wildlife and the "health" of a wetland site after a restoration.  You can also
        listen to the live audio at <a href="https://livingsounds.earth">livingsounds.earth</a>.
      </p>

      <p>
        There are several other sensor sites, too, so be sure to explore the links at the top of the
        <router-link to="/data">Data</router-link> page!
      </p>

      <media-gallery title="Media" :items="mediaLinks"/>

      <h2>Publications</h2>
      <ul id="home-pubs">
        <li>
          <a href="https://www.livingobservatory.org/learning-report">Learning from the Restoration
          of Wetlands on Cranberry Farmland: Preliminary Benefits Assessment</a>.  Living
          Observatory and collaborators — December 2020.
        </li>
        <li>
          <a href="https://resenv.media.mit.edu/pubs/theses/MaytonDissertation.pdf">Sensor Networks
          for Experience and Ecology</a>. (Ph.D. Thesis).  Brian Mayton — September 2020.
        </li>
        <li>
          <a
          href="https://resenv.media.mit.edu/pubs/papers/Duhart-Tidzam-SIRS-2018.pdf">Deep
          Learning Locally Trained Wildlife Sensing in Real Acoustic Wetland
          Environment.</a> Clement Duhart, Gershon Dublon, Brian Mayton
          and Joseph Paradiso.  In <i>Communications in Computer and
          Information Science</i>, ISSN 1865:0929 Springer.  September 2018.
          Best Paper Award - SIRS’18.
        </li>
        <li>
          <a
          href="https://resenv.media.mit.edu/pubs/theses/DublonDissertation.pdf">Sensor(y)
          Landscapes: Technologies for New Perceptual Sensibilities.</a> (Ph.D. Thesis). Gershon
          Dublon — June 2018.
        </li>
        <li>
          <a
          href="https://resenv.media.mit.edu/pubs/papers/2017_MaytonDublon_SensoryLandscapes_Presence.pdf">The
          Networked Sensory Landscape: Capturing and Experiencing Ecological
          Change Across Scales.</a> Brian Mayton, Gershon Dublon, Spencer
          Russell, Evan F. Lynch, Don Derek Haddad, Vasant Ramasubramanian,
          Clement Duhart, Glorianna Davenport, and Joseph A.  Paradiso.
          <i>Presence: Teleoperators and Virtual Environments</i>, 26(2), MIT
          Press, 2017.
        </li>
        <li>
          <a
          href="https://resenv.media.mit.edu/pubs/papers/resynthesizing_reality_SIGGRAPH17.pdf">Resynthesizing
          Reality: Driving Vivid Virtual Environments from Sensor Networks.</a>
          Don Derek Haddad, Gershon Dublon, Brian Mayton, Spencer Russell, Xiao
          Xiao, Ken Perlin, Joseph A. Paradiso. Presented at SIGGRAPH17 30 July
          - 3 August, Los Angeles, CA
        </li>
      </ul>

    </page-template>
  </main-view>
</template>

<script>
import MediaGallery from '@/components/MediaGallery.vue';
import MediaThumbnail from '@/components/MediaThumbnail.vue';

export default {
  name: 'Home',
  components: {
    MediaGallery,
    MediaThumbnail
  },
  computed: {
    mediaLinks() {
      return this.$store.state.home.mediaLinks;
    }
  }
}
</script>

<style>

  #home-pubs li {
    list-style-type: none;
  }

  #home-pubs li+li {
    margin-top: 1em;
  }

</style>
