export default {
    state: {
        mediaLinks: [
            { id: 'arcofchange',
              thumb: 'img/thumb_arc.jpg',
              href: "https://vimeo.com/359648748",
              caption: "Tracking the Arc of Change"},
            { id: 'doppelmarsh',
              thumb: 'img/thumb_doppelmarsh.jpg',
              href: "https://vimeo.com/240549912",
              caption: "Doppelmarsh - VR Sensor Network Browser"},
            { id: 'creature',
              href: "https://vimeo.com/240548776",
              caption: "Sensor Creatures" },
            { id: 'hakoniwa',
              href: "https://vimeo.com/212681207",
              caption: "Hakoniwa — Sensor-Driven Mini AR Landscape" },
            { id: 'sensorchimes',
              href: "https://vimeo.com/205620772",
              caption: "SensorChimes — Musical Mapping for Sensor Networks" },
            { id: 'doppelmarsh3',
              href: "https://vimeo.com/212653274",
              caption: "Doppelmarsh v3" },
            { id: 'dec3_2015',
              href: "https://www.youtube.com/watch?v=vQkh3ko1MxE",
              caption: "Timelapse — Cell 3 Work Begins" },
            { id: 'emc',
              href: "https://www.youtube.com/watch?v=T8yZK_Pw32k",
              caption: "EMC documentary about the project" },
            { id: 'nick_viz',
              href: "https://vimeo.com/132137535",
              caption: "MarshVis Web Visualization" },
            { id: 'virtual',
              href: "https://www.youtube.com/watch?v=09FR6zDl0GE",
              caption: "Doppelmarsh (2014)" }
        ],
    },
    mutations: {

    },
    actions: {

    }
}
