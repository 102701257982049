<template>
  <img  class="graphite" :src="src" :alt="src"/>
</template>

<script>
  import {mapState} from 'vuex';
  import units from '@/units.js';
  import {prefabPlots} from '@/plots.js';

  export default {
    name: 'GraphitePlot',
    props: {
      config: Object,
    },
    computed: {
      ...mapState({
        graphiteBase: state => state.graphiteBase,
        graphiteMetricBase: state => state.graphiteMetricBase,
        salt: state => state.graphiteSalt.getTime() / 1000
      }),
      ...mapState('units', {
        preferredUnits: state => state.preferredUnits
      }),
      ...mapState('data', {
        defaultSpan: state => state.plotSpan,
        site: state => state.site
      }),
      opts() {
        if(this.config.prefab === undefined) {
          return this.config;
        } else {
          let prefabConfig = prefabPlots[this.config.prefab] || {};
          let config = Object.assign({}, prefabConfig, this.config);
          return config;
        }
      },
      src() {
        let graphiteProps = ['yMin', 'yMax', 'lineMode', 'connectedLimit',
          'lineWidth', 'logBase', 'hideLegend'];
        let unitProps = ['yMin', 'yMax'];

        let args = {
          target: this.target,
          width: this.opts.width || 500,
          height: this.opts.height || 400,
          lineWidth: this.opts.lineWidth || 2,
          from: this.opts.from || this.defaultSpan.from,
          until: this.opts.until || this.defaultSpan.until,
          _salt: this.salt
        };

        if(this.title !== undefined) {
          args.title = this.title;
        }

        for(let prop of graphiteProps) {
          if(this.opts[prop] !== undefined) {
            if(unitProps.indexOf(prop) >= 0) {
              let converted = units.convert(this.unit, {unit: this.opts.unit,
                value: this.opts[prop]});
              args[prop] = converted.value;
            } else {
              args[prop] = this.opts[prop];
            }
          }
        }


        return this.graphiteBase + "?" + Object.keys(args).map(k => k + "=" +
          encodeURI(args[k])).join("&");
      },
      devicesObjs() {
        if(this.site === null || this.opts.metric === undefined || 
         this.opts.deviceGroups === undefined) {
          return undefined;
        }
        let deviceLimit = this.opts.deviceLimit || 20;
        let site = this.site;
        let devices = [];
        for(let groupName of this.opts.deviceGroups) {
          let group = site.deviceGroups[groupName];
          if(group === undefined) continue;
          for(let id of group) {
            if(devices.length >= deviceLimit) break;
            let device = site.devices[id];
            if(device === undefined) continue;
            if(device.props['web:hide'] == 'true') continue;
            if(device.metrics[this.opts.metric] !== undefined) {
              devices.push(device);
            }
          }
        }

        function sortKey(device) {
          let k = device.props.sortOrder !== undefined?
            device.props.sortOrder : device.alias;
          console.log(k);
          return k;
        }

        devices.sort((a, b) => sortKey(a) > sortKey(b));

        return devices;
      },
      devices() {
        if(this.devicesObjs === undefined) return undefined;
        return this.devicesObjs.map(x => x.alias);
      },
      target() {
        if(this.opts.targets !== undefined) {
          return this.opts.targets.map(x => this.targetString(x)).join("&target=");
        } else if(this.opts.target !== undefined) {
          return this.targetString(this.opts.target);
        } else if(this.devices !== undefined) {
          let metricBase = this.opts.metricBase || this.graphiteMetricBase;
          if(this.opts.separateTargets) {
            let targets = [];
            for(let device of this.devicesObjs) {
              let alias = device.props.title !== undefined ? 
                `${device.props.title} (${device.alias})` : device.alias;
              let target = {
                target: metricBase + device.alias + "." + this.opts.metric,
                alias
              }
              targets.push(target);
            }
            return targets.map(x => this.targetString(x)).join("&target=");
          } else {
            let target = {
              target: metricBase + "{" + this.devices.join(',') + "}." + this.opts.metric,
              aliasByNode: metricBase.split('.').length-1,
              sortByName: true
            }
            if(this.opts.targetOpts !== undefined) {
              Object.assign(target, this.opts.targetOpts);
            }
            return this.targetString(target);
          }
        }
      },
      unit() {
        let metric = this.opts.unitType || this.opts.metric;
        let srcUnit = this.opts.unit;
        if(metric === undefined) {
          return srcUnit;
        }

        let dstUnit = this.preferredUnits[metric];
        if(dstUnit === undefined) {
          return srcUnit;
        }

        return dstUnit;
      },
      title() {
        if(this.opts.title === undefined) return undefined;
        let title = this.opts.title;
        if(this.unit !== undefined) {
          title = `${title} (${this.unit})`;
          /* Graphite barfs on unicode, so strip the degree sign */
          title = title.replace("°", "");
        }
        return title;
      }
    },
    methods: {
      targetString(target) {
        let appOrder = ['sortByName','movingAverage','offset','scale','summarize','color',
          'aliasByNode','alias'];
        if(typeof(target) === "string") {
          if(this.unit === this.opts.unit) {
            return target;
          } else {
            let converted = units.convertGraphite(this.unit, {target, unit: this.opts.unit});
            target = `alias(${converted.target},"${target}")`;
            return target;
          }
        } else if(typeof(target) === "object") {
          let config = target;
          let converted = units.convertGraphite(this.unit, {target: config.target,
            unit: this.opts.unit});
          target = converted.target;
          for(let key of appOrder) {
            if(config[key] !== undefined) {
              let arg = config[key];
              if(typeof(arg) === "string") {
                target = `${key}(${target},"${arg}")`;
              } else if(typeof(arg) === "number") {
                target = `${key}(${target},${arg})`;
              } else if(typeof(arg) === "boolean" && arg == true) {
                target = `${key}(${target})`;
              }
            }
          }
        }
        return target;
      }

    }
  }
</script>

<style scoped>
  .graphite {
    border-radius: 8px;
  }
</style>


