<template>
  <data-section>
    <audio-player ref="player"/>
    <div class="camera-container" @mousemove="onMouseMove"
      @click="onClick">
      <video class="videotest" ref="videotest"></video>
      <template v-if="camera.online">
        <hls-camera-view 
          v-if="hlsSupported && camera.hls !== undefined"
          :url="camera.hls"/>
        <still-camera-view
          v-else
          :url="camera.lastImage" :name="camera.id"/>
        <wx-overlay v-if="camera.wxOverlay !== undefined" :config="camera.wxOverlay"/>
      </template>
      <template v-else>
        <div class="video-overlay">
          <div>
          <font-awesome-icon icon="video-slash" class="icon-48"/>
          <h3>Camera Offline</h3>
          <p>
            Sorry, this camera isn't working right now.<br/>
            We'll be out in the field fixing it soon!
          </p>
          <p class="aside">
            (Sometimes critters chew on the cables.  It's one of the perils of 
            putting technology in nature!)
          </p>
          </div>
        </div>
      </template>
      <audio-overlay v-if="camera.audio !== undefined" :sources="camera.audio"
        :visible="audioControls" ref="audioWidget" @click="audioClick"/>
    </div>
    <CameraStrip v-if="cameras.length > 1" :cameras="cameras" v-model="activeIndex"/>
  </data-section>
</template>

<script>
  import {mapMutations, mapState} from 'vuex';
  import DataSection from '@/components/DataSection.vue';
  
  import CameraStrip from '@/components/CameraStrip.vue';
  import HlsCameraView from '@/components/HLSCameraView.vue';
  import StillCameraView from '@/components/StillCameraView.vue';
  import WxOverlay from '@/components/WxOverlay.vue';
  import AudioOverlay from '@/components/AudioOverlay.vue';
  import AudioPlayer from '@/components/AudioPlayer.vue';

  import Hls from 'hls.js/dist/hls.light.min.js';

  export default {
    name: 'CameraViewer',
    components: {
      CameraStrip,
      HlsCameraView,
      StillCameraView,
      DataSection,
      WxOverlay,
      AudioOverlay,
      AudioPlayer
    },
    props: {
      cameras: Array
    },
    computed: {
      ...mapState('data', {
        audio: state => state.audio
      }),
      camera() {
        return this.cameras[this.activeIndex];
      },
      hlsSupported() {
        return Hls.isSupported() || 
          document.createElement('video').canPlayType('application/vnd.apple.mpegurl');
      }
    },
    mounted() {
      this.$nextTick(function() {
        this.loadAudio();
      });

      /* Default to the first camera that's online */
      for(this.activeIndex=0; this.activeIndex<this.cameras.length; this.activeIndex++) {
        if(this.cameras[this.activeIndex].online) {
          break;
        }
      }
      if(!this.cameras[this.activeIndex].online) {
        this.activeIndex = 0;
      }
    },
    methods: {
      ...mapMutations('data', [
        'SET_AUDIO_SOURCE'
      ]),
      onClick() {
        this.$refs.audioWidget.clearMenu();
      },
      audioClick() {
        /* The play state of the player should update according to the Vuex 
         * store, but browsers often require the play event to come from
         * mouse event handler to block autoplaying content.  Here we call
         * the play method on the player after every click interaction with
         * the selector widget, which will cause it to play if appropriate.
         */
        this.$refs.player.play();
      },
      onMouseMove() {
        this.audioControls = true;
        if(this.controlsTimeout !== null) {
          clearTimeout(this.controlsTimeout);
        }

        this.controlsTimeout = setTimeout(() => {
          this.audioControls = false;
          this.controlsTimeout = null;
        }, 5000);
      },
      loadAudio() {
        let foundSource = false;
        if(this.camera.audio === undefined) {
          console.log('no camera audio');
          this.SET_AUDIO_SOURCE(null);
        } else if(this.audio.source !== null && this.camera.audio !== undefined) {
          for(let source of this.camera.audio) {
            if(source.id === this.audio.source.id) {
              this.SET_AUDIO_SOURCE(source);
              foundSource = true;
              break;
            }
          }
          if(!foundSource) {
            this.SET_AUDIO_SOURCE(this.camera.audio[0]);
          }
        } else {
          console.log('load default audio');
          this.SET_AUDIO_SOURCE(this.camera.audio[0]);
        }
      }
    },
    data() {
      return {
        activeIndex: 0,
        audioControls: false,
        controlsTimeout: null
      }
    },
    watch: {
      camera() {
        this.loadAudio()
      }
    }
  }
</script>

<style>
  .videotest {
    display: none;
  }

  .video-overlay {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .aside {
    font-size: 80%;
  }


  .camera-container {
    width: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #333;
    padding-top: 56.25%;
    overflow: hidden;
  }

  .icon-48 {
    font-size: 48px;
  }

  .camera-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
</style>
